import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import CreateDialog from "../components/dialogs/CreateDialog";
import CreateNewFolderTwoToneIcon from "@mui/icons-material/CreateNewFolderTwoTone";
import Folder from "./Folder";
import { Folder as FolderType } from "../api/types";
import { TreeItem } from "./TreeItem";
import { useCreateFolderMutation } from "../api/mutations";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useState } from "react";

export default function ListsMenu(props: { folders: FolderType[] | null }) {
  // CONTEXT
  const { email } = useOktaAuthContext();

  // STATE
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);

  // MUTATIONS
  const createFolder = useCreateFolderMutation();

  return (
    <TreeItem
      itemId="LISTS"
      label={
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Typography fontWeight={500}>LISTS</Typography>
          <Tooltip title="Create Folder">
            <IconButton
              color="primary"
              onClick={(event) => {
                setCreateFolderModalOpen(true);
                event.stopPropagation();
              }}
              size="small"
              sx={{
                padding: 0,
              }}
            >
              <CreateNewFolderTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    >
      <CreateDialog
        key={"create-folder-dialog"}
        open={createFolderModalOpen}
        rows={1}
        title="Create Folder"
        label="Folder Name"
        setOpen={setCreateFolderModalOpen}
        yesAction={(newValue) => {
          createFolder.mutate({ name: newValue, owner: email } as FolderType);
          setCreateFolderModalOpen(false);
        }}
      />

      <Divider />

      {props.folders &&
        props.folders.map((folder: FolderType, index) => {
          return <Folder key={index} index={index} folder={folder} />;
        })}
    </TreeItem>
  );
}
