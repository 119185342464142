import { AppBar, Toolbar as MaterialToolbar } from "@mui/material";

import ToolbarLeft from "./ToolbarLeft";
import ToolbarRight from "./ToolbarRight";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";

export const APPBAR_HEIGHT_AS_NUM = 60;
export const APPBAR_HEIGHT = `${APPBAR_HEIGHT_AS_NUM}px`;

export default function Toolbar() {
  const { email } = useOktaAuthContext();

  if (email && email !== "firevisas@chicagofirefc.com") {
    return (
      <AppBar
        position="sticky"
        color="primary"
        sx={{
          minHeight: APPBAR_HEIGHT,
          height: APPBAR_HEIGHT
        }}
      >
        <MaterialToolbar sx={{ minHeight: APPBAR_HEIGHT, height: APPBAR_HEIGHT }}>
          <ToolbarLeft />
          <ToolbarRight />
        </MaterialToolbar>
      </AppBar>
    )
  } else {
    return <></>;
  }
}
