import { Box, Switch, Typography } from "@mui/material";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useThemeContext } from "../contexts/CustomThemeContext";

interface PlayerTableViewSwitchProps {
  viewMode: string;
  setViewMode: (newViewMode: string) => void;
}

export const PlayerTableViewSwitch = (props: PlayerTableViewSwitchProps) => {
  // CONTEXTS
  const { theme } = useThemeContext();

  // VARIABLES
  const VIEW_MODE_ANALYTICS = "analytics";
  const VIEW_MODE_SCOUTING = "scouting";

  // FUNCTIONS
  const onSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      props.setViewMode(VIEW_MODE_SCOUTING);
    } else {
      props.setViewMode(VIEW_MODE_ANALYTICS);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color:
            props.viewMode === VIEW_MODE_ANALYTICS
              ? theme.palette.primary.main
              : "#EBEBE4",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: 500,
          }}
        >
          ANALYSIS
        </Typography>
        <QueryStatsIcon
          sx={{
            height: 28,
          }}
        />
      </Box>
      <Switch
        checked={props.viewMode === VIEW_MODE_SCOUTING}
        onChange={onSwitch}
        inputProps={{ "aria-label": "controlled" }}
        sx={{
          ".css-lfcvai-MuiButtonBase-root-MuiSwitch-switchBase": {
            // Controls default (unchecked) color for the thumb
            color: theme.palette.primary.main,
          },
          ".css-lfcvai-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
            {
              backgroundColor: theme.palette.primary.main,
              opacity: 0.3,
            },
          ".css-1rrce0h-MuiSwitch-track": {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.3,
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color:
            props.viewMode === VIEW_MODE_SCOUTING
              ? theme.palette.primary.main
              : "#EBEBE4",
        }}
      >
        <PersonSearchIcon
          sx={{
            height: 28,
          }}
        />
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          SCOUTING
        </Typography>
      </Box>
    </Box>
  );
};
