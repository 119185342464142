import { GET_NOTIFICATIONS, GET_PLAYER_NOTES } from "../api/keys";
import { createContext, useContext, useEffect, useState } from "react";

import { Notification } from "../api/types";
import useLocalStorage from "react-use-localstorage";
import { useNotifications } from "../api/queries";
import { useQueryClient } from "react-query";
import { useWebSocketContext } from "./WebSocketContext";

export interface NotificationContextInterface {
    notificationsAvailable: boolean;
    notifications: Notification[];
    setNotificationsAvailable: (notificationsAvailable: boolean) => void
}

const defaultState = {
    notificationsAvailable: false,
    notifications: [],
    setNotificationsAvailable: () => { }
};

const NotificationsContext =
    createContext<NotificationContextInterface>(defaultState);

export const NotificationsProvider = ({ children }: { children: React.ReactNode }) => {
    const { ws } = useWebSocketContext();
    const [prevNotifications, setPrevNotifications] = useLocalStorage(
        "notifications",
        undefined
    );

    const [notifications, setNotifications] = useState<Notification[]>([])
    const [notificationsAvailable, setNotificationsAvailable] = useState<boolean>(false);
    const notificationsResult = useNotifications();
    const queryClient = useQueryClient();

    if (ws !== undefined) {
        ws.onmessage = (ev) => {
            // console.debug(ev.data);
            if (ev.data === GET_PLAYER_NOTES || ev.data === GET_NOTIFICATIONS) {
                queryClient.invalidateQueries(GET_PLAYER_NOTES);
                queryClient.invalidateQueries(GET_NOTIFICATIONS);
                setNotificationsAvailable(true);
            }
        }
    }

    useEffect(() => {
        // console.debug(prevNotifications);
        // console.debug(notificationsResult.data);

        if ((notificationsResult?.data)) {


            if (prevNotifications) {
                const previousNotifications: Notification[] = JSON.parse(prevNotifications);

                if ((notificationsResult?.data.length > 0 && previousNotifications.length > 0)) {
                    if (notificationsResult?.data[0].action_time > previousNotifications[0].action_time) {
                        console.debug('new notifications received!');
                        setNotificationsAvailable(true);
                    }

                    if (notificationsResult?.data[0].action_time < previousNotifications[0].action_time) {
                        setNotificationsAvailable(false);
                    }
                }

                setNotifications(notificationsResult.data.map((n: Notification) => {
                    n.isNew = !previousNotifications.some((prevNotification: Notification) => {
                        return prevNotification.action_time === n.action_time
                            && prevNotification.action === n.action
                            && prevNotification.action_by === n.action_by
                            && prevNotification.content === n.content
                            && prevNotification.type === n.type
                    })
                    return n
                }))
                setPrevNotifications(JSON.stringify(notificationsResult.data));
            } else {
                setNotifications(notificationsResult.data)
                setPrevNotifications(JSON.stringify(notificationsResult.data));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsResult?.data]);

    // console.debug(notifications)
    // console.debug(notificationsAvailable)

    return (
        <NotificationsContext.Provider value={{
            notificationsAvailable: notificationsAvailable,
            notifications: notifications,
            setNotificationsAvailable: setNotificationsAvailable
        }}>
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotificationsContext = () => useContext(NotificationsContext);
