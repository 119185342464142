import {
  Avatar,
  Box,
  Chip,
  Collapse,
  Divider,
  Grid2 as Grid,
  IconButton,
  LinearProgress,
  Link,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip as MuiTooltip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { GetRatingLabel, GetRatings } from "../utils";
import { Player, ScoutingReport } from "../../../api/types";
import { SEVERITY_INFO, useAlertContext } from "../../../contexts/AlertContext";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import {
  useCreateScoutingReportMutation,
  useUpdateScoutingReportMutation,
} from "../../../api/mutations";

import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import CloseButton from "../../../components/buttons/CloseButton";
import CreatePlayerDialog from "./CreatePlayerDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import TextField from "@mui/material/TextField";
import { useDebounce } from "use-debounce";
import { useOktaAuthContext } from "../../../contexts/OktaAuthContext";
import { usePlayerSearch } from "../../../api/queries";

export interface MutateScoutingReportDialogProps {
  player: Player | undefined;
  open: boolean;
  report: ScoutingReport | undefined;
  setReportInContext: Dispatch<SetStateAction<ScoutingReport | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

export default function MutateScoutingReportDialog(
  props: MutateScoutingReportDialogProps
) {
  // CONTEXT
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { name } = useOktaAuthContext();
  // const { team } = useTeamContext();
  const { setAlertOptions } = useAlertContext();

  // VARIABLES
  const ratings = GetRatings();

  // STATE
  const [searchForPlayer, setSearchForPlayer] = useState<boolean>(true);

  const [additionalInfoExpanded, setAdditionalInfoExpanded] =
    useState<boolean>(false);
  const [stanceExpanded, setStanceExpanded] = useState<boolean>(true);
  const [playerId, setPlayerId] = useState<number | undefined>(
    props.player?.id
  );
  const [playerName, setPlayerName] = useState<string | undefined>(
    props.player?.name || ""
  );

  const [grouping, setGrouping] = useState<string | undefined>(undefined);
  const [rating, setRating] = useState<number | undefined>(undefined);
  const [projectedRating, setProjectedRating] = useState<number | undefined>(
    undefined
  );

  // additional info
  const [team, setTeam] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [opponent, setOpponent] = useState<string>("");
  const [opponentResistance, setOpponentResistance] = useState<number | string>(
    ""
  );
  const [location, setLocation] = useState<string>("");

  const [debouncedPlayerName] = useDebounce(playerName, 300);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setScout] = useState<string>("");
  const [date, setDate] = useState<Date | undefined>();
  // stance
  const [stance, setStance] = useState<string>("");

  const [openCreatePlayerDialog, setOpenCreatePlayerDialog] =
    useState<boolean>(false);

  // QUERIES
  const searchPlayerQuery = usePlayerSearch(
    {
      query: debouncedPlayerName || "",
      by: "name",
    },
    3,
    1
  );

  // MUTATIONS
  const createScoutingReport = useCreateScoutingReportMutation();
  const updateScoutingReport = useUpdateScoutingReportMutation(
    props.setReportInContext
  );

  // REFS
  const formRef = useRef();

  // FUNCTIONS
  const handleSubmit = () => {
    const payload = {
      grouping: grouping,
      location: location,
      opponent: opponent,
      opponent_resistance: opponentResistance,
      player_id: playerId,
      position: position,
      projected_rating: projectedRating,
      rating: rating,
      scout: name,
      stance: stance,
      team: team,
    };

    // console.log(payload);

    if (!props?.report?.id) {
      const obj = Object.fromEntries(
        Object.entries({
          ...payload,
          date: new Date(),
        }).filter(([_, v]) => v !== null && v !== "")
      );
      console.debug(obj);
      createScoutingReport.mutate(obj as unknown as ScoutingReport);
    } else {
      const obj = Object.fromEntries(
        Object.entries({
          ...payload,
          id: props.report && props.report.id,
          date: date,
        }).filter(([_, v]) => v !== null && v !== "")
      );
      console.debug(obj);
      updateScoutingReport.mutate(obj as unknown as ScoutingReport);
    }

    props.setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setPlayerId(undefined);
    setPlayerName("");
    setSearchForPlayer(true);

    setGrouping(undefined);
    setRating(undefined);
    setProjectedRating(undefined);

    setAdditionalInfoExpanded(false);
    setPosition("");
    setOpponent("");
    setOpponentResistance("");
    setLocation("");

    setStance("");
  };

  const onChangePlayerName = (newValue: string) => {
    setPlayerId(undefined);
    setPlayerName(newValue);
  };

  const onPlayerSearchResultClick = useCallback(
    (player: Player) => {
      // console.debug(player.id);
      // console.debug(player);
      // setClub(scouted_player.team);
      // setDate(undefined);
      // setBirthYear(
      //   moment(scouted_player.birthdate, "YYYY-MM-DD").year().toString()
      // );
      // setDateOfBirth(moment(scouted_player.birthdate).toDate());
      // setFirstName(scouted_player.first_name);
      // setFoot(scouted_player.foot);
      // setLastName(scouted_player.last_name);
      setPlayerId(player.id);
      setPlayerName(player.name || "");
    },
    [setPlayerId]
  );

  // EFFECTS
  useEffect(() => {
    // Set sane defaults
    props.report?.grouping
      ? setGrouping(props.report?.grouping)
      : setGrouping("");
    props.report?.team ? setTeam(props.report?.team) : setTeam("");
    props.report?.opponent
      ? setOpponent(props.report.opponent)
      : setOpponent("");
    props.report?.opponent_resistance
      ? setOpponentResistance(props.report.opponent_resistance)
      : setOpponentResistance("");
    props.report?.projected_rating
      ? setRating(Math.floor(props?.report?.projected_rating))
      : setProjectedRating(undefined);
    props.report?.rating
      ? setRating(Math.floor(props?.report?.rating))
      : setRating(undefined);
    props.report?.player_id
      ? setSearchForPlayer(props.report.player_id ? false : true)
      : setSearchForPlayer(true);
    props.report?.player_id
      ? setPlayerId(props.report.player_id)
      : setPlayerId(undefined);
    props.report?.position
      ? setPosition(props.report.position)
      : setPosition("");
    props.report?.scout ? setScout(props.report.scout) : setScout("");
    props.report?.date ? setDate(props.report.date) : setDate(undefined);

    props.report?.stance ? setStance(props.report.stance) : setStance("");
  }, [props.report]);

  useEffect(() => {
    setPlayerId(props.player?.id);
    setPlayerName(props.player?.name);
  }, [props.player]);

  return (
    <Box key={"mutate-scouting-report-dialog-outer-box"}>
      <CreatePlayerDialog
        open={openCreatePlayerDialog}
        setOpen={setOpenCreatePlayerDialog}
        closeDialog={() => {
          setOpenCreatePlayerDialog(false);
        }}
        name={playerName || ""}
      />

      <Dialog
        open={props.open}
        onClose={(event: object, reason: string) => {
          if (reason === "escapeKeyDown") {
            props.onClose();
            resetForm();
          }
        }}
        key={"Scouting Report"}
        fullScreen={fullScreen}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: fullScreen ? 0 : 5,
            width: "100%",
          },
        }}
      >
        <DialogTitle
          key={"mutate-scouting-report-dialog-title"}
          sx={{ textAlign: "center" }}
        >
          SCOUTING REPORT
        </DialogTitle>

        <ValidatorForm
          ref={formRef.current}
          onSubmit={() => name && handleSubmit()}
          // onError={errors => console.debug(errors)}
        >
          <DialogContent key={"mutate-scouting-report-dialog-content"}>
            <CloseButton
              position="absolute"
              top={5}
              left={5}
              onClick={() => {
                props.onClose();
                resetForm();
              }}
            />

            <Stack key={"mutate-scouting-report-dialog-stack"} spacing={2}>
              {/* <FormControlLabel
                label="Domestic"
                control={
                  <Checkbox
                    checked={domestic}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => setDomestic(checked)}
                  />
                }
              /> */}

              {searchForPlayer && (
                <Grid container>
                  <Grid size={{ xs: 11 }}>
                    <TextValidator
                      errorMessages={["this field is required"]}
                      id="report-dialog-player-name-select"
                      label="Player Name"
                      name="player_name"
                      validators={["required"]}
                      value={playerName}
                      variant="outlined"
                      fullWidth
                      onChange={(e: any) => onChangePlayerName(e.target.value)}
                    />
                  </Grid>

                  <Grid size={{ xs: 1 }}>
                    <MuiTooltip title="Clear">
                      <IconButton
                        aria-label="cancel"
                        size="large"
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        <ClearIcon fontSize="inherit" />
                      </IconButton>
                    </MuiTooltip>
                  </Grid>
                </Grid>
              )}

              {(searchPlayerQuery.isFetching ||
                searchPlayerQuery.isLoading) && (
                <LinearProgress color="secondary" />
              )}

              {/* {searchPlayerQuery.isFetched && searchForPlayer && ( */}
              <MenuList disablePadding dense>
                {searchPlayerQuery.data?.data.map(
                  (player: Player, i: number) => {
                    if (
                      player?.id &&
                      ((playerId && player.id === playerId) || !playerId)
                    ) {
                      return (
                        <MenuItem
                          key={i}
                          dense
                          onClick={() => {
                            onPlayerSearchResultClick(player);
                          }}
                          selected={playerId === player.id}
                          sx={{ borderRadius: 2 }}
                        >
                          <ListItemAvatar>
                            <Avatar src={player.image} />
                          </ListItemAvatar>

                          <ListItemText
                            primary={
                              <Stack
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Link
                                  noWrap={true}
                                  onClick={() => {
                                    if (player.tm_link) {
                                      window.open(
                                        player.tm_link,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    } else {
                                      setAlertOptions({
                                        message: `${
                                          player.name
                                            ? player.name
                                            : "this player"
                                        } doesn't have a transfermakrt profile`,
                                        severity: SEVERITY_INFO,
                                      });
                                    }
                                  }}
                                >
                                  {player.name}
                                </Link>

                                {player.age}
                              </Stack>
                            }
                            secondary={
                              <Stack
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography variant="caption">
                                  {player.team}
                                </Typography>
                                <Typography variant="caption">
                                  {player.nationality}
                                </Typography>
                              </Stack>
                            }
                          />
                        </MenuItem>
                      );
                    }

                    return null;
                  }
                )}

                {playerId === undefined && searchForPlayer && (
                  <MenuItem
                    dense
                    onClick={() => {
                      setOpenCreatePlayerDialog(true);
                      props.setOpen(false);
                      resetForm();
                    }}
                  >
                    <ListItemAvatar sx={{ ml: 1 }}>
                      <PersonAddAlt1Icon />
                    </ListItemAvatar>
                    <ListItemText primary="Add Player" />
                  </MenuItem>
                )}
                <Divider />
              </MenuList>

              {(playerId || !searchForPlayer) && (
                <Stack spacing={2}>
                  <SelectValidator
                    id="report-dialog-grouping-select"
                    errorMessages={["this field is required"]}
                    fullWidth
                    label="Grouping"
                    name="grouping"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setGrouping(event.target.value)
                    }
                    sx={{
                      width: "100%",
                      minWidth: "100%",
                    }}
                    value={grouping}
                    validators={["required"]}
                    variant="outlined"
                  >
                    <MenuItem
                      key="grouping-empty"
                      value={undefined}
                      sx={{ width: "100%" }}
                    />
                    {["FIRST TEAM", "SECOND TEAM", "ACADEMY"].map(
                      (g: string, i: number) => {
                        return (
                          <MenuItem key={g} value={g}>
                            {g}
                          </MenuItem>
                        );
                      }
                    )}
                  </SelectValidator>

                  <Divider />

                  <SelectValidator
                    id="report-dialog-rating-select"
                    errorMessages={["this field is required"]}
                    fullWidth
                    label="Rating"
                    name="rating"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setRating(event.target.value as unknown as number)
                    }
                    sx={{
                      width: "100%",
                      minWidth: "100%",
                    }}
                    value={rating}
                    validators={["required"]}
                    variant="outlined"
                  >
                    <MenuItem
                      key="rating-empty"
                      value={undefined}
                      sx={{ width: "100%" }}
                    />
                    {ratings.map((rating: string, i: number) => {
                      return (
                        <MenuItem key={`rating-${rating}`} value={i}>
                          {GetRatingLabel(i)}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>

                  <SelectValidator
                    id="report-dialog-projectd-rating-select"
                    errorMessages={["this field is required"]}
                    fullWidth
                    label="Projected Rating"
                    name="projected-rating"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setProjectedRating(
                        event.target.value as unknown as number
                      )
                    }
                    sx={{
                      width: "100%",
                      minWidth: "100%",
                    }}
                    value={projectedRating}
                    validators={["required"]}
                    variant="outlined"
                  >
                    <MenuItem
                      key="projected-rating-empty"
                      value={undefined}
                      sx={{ width: "100%" }}
                    />
                    {ratings.map((rating: string, i: number) => {
                      return (
                        <MenuItem key={`projected-${rating}`} value={i}>
                          {GetRatingLabel(i)}
                        </MenuItem>
                      );
                    })}
                  </SelectValidator>
                </Stack>
              )}

              <>
                <Divider
                  onClick={() =>
                    setAdditionalInfoExpanded(!additionalInfoExpanded)
                  }
                >
                  <Chip
                    color="primary"
                    label="ADDITIONAL INFO"
                    sx={{ cursor: "pointer", marginTop: 2 }}
                  />
                </Divider>

                <Collapse
                  key={"additional-info-collapse"}
                  in={additionalInfoExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <Stack spacing={2}>
                    <TextValidator
                      id="report-dialog-team"
                      label="Team"
                      name="team"
                      type="text"
                      variant="outlined"
                      rows={1}
                      value={team}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setTeam(event.target.value)
                      }
                      fullWidth
                    />

                    <TextValidator
                      id="report-dialog-position"
                      label="Position"
                      name="position"
                      type="text"
                      variant="outlined"
                      rows={1}
                      value={position}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setPosition(event.target.value)
                      }
                      fullWidth
                    />

                    <TextValidator
                      id="value"
                      label="Opponent"
                      name="opponent"
                      type="text"
                      variant="outlined"
                      rows={1}
                      value={opponent}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setOpponent(event.target.value)
                      }
                      fullWidth
                    />

                    <TextValidator
                      select
                      id="report-dialog-opponent-resistance-select"
                      label="Opponent Resistance"
                      name="opponent_resistance"
                      value={opponentResistance}
                      variant="outlined"
                      fullWidth
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setOpponentResistance(
                          event.target.value as unknown as number
                        )
                      }
                    >
                      {[...Array(10)].map((_, i) => {
                        return (
                          <MenuItem key={i} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        );
                      })}
                    </TextValidator>

                    <TextValidator
                      id="report-dialog-location-select"
                      label="Location"
                      name="location"
                      value={location}
                      variant="outlined"
                      fullWidth
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setLocation(event.target.value)
                      }
                    />
                  </Stack>
                </Collapse>
              </>

              <>
                <Divider onClick={() => setStanceExpanded(!stanceExpanded)}>
                  <Chip
                    color="primary"
                    label="STANCE"
                    sx={{ cursor: "pointer" }}
                  />
                </Divider>

                <Collapse
                  key={"stance-info-collapse"}
                  in={stanceExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <Stack spacing={2}>
                    <TextField
                      key={"report-dialog-stamce-textfield"}
                      margin="dense"
                      id="value"
                      label={"Stance"}
                      type="text"
                      variant="outlined"
                      multiline
                      rows={10}
                      value={stance}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setStance(event.target.value)
                      }
                      fullWidth
                    />
                  </Stack>
                </Collapse>
              </>
            </Stack>
          </DialogContent>

          <DialogActions
            key={"mutate-scouting-report-dialog-actions"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="outlined"
              type="submit"
              key="mutate-scouting-report-dialog-button"
            >
              SUBMIT
            </Button>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </Box>
  );
}
