import { Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import { Folder } from "../api/types";
import { FolderActionMenu } from "./FolderActionMenu";
import { LIST } from "./ItemTypes";
import { useDrop } from "react-dnd";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";

interface FolderLabelProps {
    index: number
    folder: Folder
}

export default function FolderLabel(props: FolderLabelProps) {
    // CONTEXT
    const theme = useTheme();
    const { email } = useOktaAuthContext();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));

    // console.debug(`${props.folder.id}:${props.folder.name}`);

    const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
        accept: LIST,
        canDrop: () => (props.folder.id !== -1),
        drop: () => ({ id: props.folder.id as number }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const isActive = canDrop && isOver;

    // VARIABLES
    const showActionMenu = props.folder.id > 0 && props.folder.owner === email;
    return (
        <Stack
            key={`${props.folder.id}-${props.index}-folder-stack`}
            direction="row"
            display="flex"
            justifyContent='space-between'
            pl={1}
            sx={{
                backgroundColor: isActive ? theme.palette.info.main : 'transparent',
                width: isScreenSmall ? '86%' : '89.25%',
                maxWidth: isScreenSmall ? '86%' : '89.25%',
            }}
            ref={dropRef}
        >
            <Tooltip title={props.folder.name} placement="right" slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 26],
                            },
                        },
                    ],
                },
            }}>
                <Typography
                    key={`${props.folder.id}-${props.index}-folder-stack-typography`}
                    variant='body2'
                    noWrap
                    mt='auto'
                    sx={{
                        maxWidth: "100%"
                    }}
                >
                    {props.folder.name}
                </Typography>
            </Tooltip>

            {showActionMenu && <FolderActionMenu index={props.index} folder={props.folder} />}
        </Stack>
    );
}
