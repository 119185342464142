import { Share, User } from "../api/types";
import { useShareMutation, useUnshareMutation } from "../api/mutations";

import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MouseEvent } from "react";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Paper from "@mui/material/Paper";
import { UseQueryResult } from "react-query";
import { useConstantsContext } from "../contexts/ConstantsContext";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useTeamContext } from "../contexts/TeamContext";

interface ShareMenuProps {
  anchorEl: null | HTMLElement;
  menuId: string;
  menuListProps: any;
  objectId: number;
  objectType: string;
  open: boolean;
  onClose: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  sharesQueryResult: UseQueryResult<Share[] | null, unknown>;
}

export default function ShareMenu(props: ShareMenuProps) {
  // Context
  const { email } = useOktaAuthContext();
  const { team } = useTeamContext();
  const { users } = useConstantsContext();

  // MUTATIONS
  const shareMutation = useShareMutation();
  const unShareMutation = useUnshareMutation();

  return (
    <Paper key={`${props.objectType}-${props.objectId}-paper`}>
      <Menu
        key={`${props.objectType}-${props.objectId}-paper-menu`}
        id={props.menuId}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={props.open}
        onClose={props.onClose}
        MenuListProps={props.menuListProps}
      >
        {users?.map((user: User) => {
          // Skip ourselves (we can't share with ourselves) except in development
          if (user.email === email && process.env.NODE_ENV !== "development") {
            return null;
          }

          // Always skip these
          if (
            user.email === "joe.mansueto@morningstar.com" ||
            user.email === "footballanalytics@chicagofirefc.com" ||
            user.email === "firevisas@chicagofirefc.com"
          ) {
            return null;
          }

          // Only allow sharing with people on your team
          if (!user.teams.includes(team)) {
            return null;
          }

          let sharedObj: Share | undefined;

          if (props.sharesQueryResult && props.sharesQueryResult.data) {
            sharedObj = props.sharesQueryResult.data?.find(
              (share: Share) =>
                share.share_with_email === user.email &&
                share.object_id === props.objectId &&
                share.object_type === props.objectType
            );
          }

          const onClick = (
            event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>
          ) => {
            sharedObj
              ? unShareMutation.mutate(sharedObj)
              : shareMutation.mutate({
                  object_id: props.objectId,
                  object_type: props.objectType,
                  sharer_email: email,
                  share_with_email: user.email,
                } as Share);
            event.stopPropagation();
          };

          return (
            <MenuItem
              key={`${user.email}-${props.objectType}-${props.objectId}-paper-menuitem`}
              onClick={(event) => onClick(event)}
            >
              {sharedObj ? (
                <CheckIcon
                  key={`${props.objectType}-${props.objectId}-${user.email}-check-icon`}
                  sx={{ color: "green" }}
                />
              ) : (
                <NotInterestedIcon
                  key={`${props.objectType}-${props.objectId}-${user.email}-not-interested-icon`}
                  sx={{ color: "red" }}
                />
              )}

              <ListItemText
                key={`${props.objectType}-${props.objectId}-paper-menu-item-list-item-text`}
                primaryTypographyProps={{ noWrap: true }}
                sx={{
                  paddingLeft: 1,
                }}
              >
                {user.name} ({user.email})
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </Paper>
  );
}
