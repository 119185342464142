import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";

import { DepthChartPlayer } from "../../api/types";
import { Draggable } from "@hello-pangea/dnd";
import ReactDOM from "react-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import { SEARCH_PARAMS_PLAYER_ID } from "../../constants";
import { useDeleteDepthChartPlayerMutation } from "../../api/mutations";
import { useSearchParams } from "react-router-dom";

interface PitchPlayerDraggableListItemProps {
  depthChartId: number;
  position: string;
  player: DepthChartPlayer;
  index: number;
  editMode: boolean;
  height: number;
}

const portal: HTMLElement = document.createElement("div");
portal.classList.add("pitch-player-portal");

if (!document.body) {
  throw new Error("body not ready for portal creation!");
}

document.body.appendChild(portal);

export default function PitchPlayerDraggableListItem(
  props: PitchPlayerDraggableListItemProps
) {
  // CONTEXTS
  const [searchParams, setSearchParams] = useSearchParams();

  // MUTATIONS
  const deleteDepthChartPlayer = useDeleteDepthChartPlayerMutation();

  return (
    <>
      {props.player.id && (
        <Draggable
          draggableId={`depth-chart-draggable-${props.depthChartId}-${props.position}-${props.player.id}`}
          index={props.index}
          isDragDisabled={!props.editMode}
        >
          {(provided, snapshot) => {
            // To fixed a render issue when dragging items in list, we need to assign to item to a
            // portal -- another DOM node outside of the component tree -- to overcome this positioning bug.
            // Honestly, I don't entirely understand how this works but it worked :D
            //
            // https://github.com/vtereshyn/react-beautiful-dnd-ru/blob/master/docs/patterns/using-a-portal.md
            // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx
            //

            const usePortal = snapshot.isDragging;

            let item = (
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={[
                  snapshot.isDragging
                    ? {}
                    : {
                        display: "flex",
                        alignItems: "center",
                        padding: "2px 0px 0px 2px",
                      },
                  { height: props.height },
                ]}
                onClick={() => {
                  if (!props.editMode) {
                    searchParams.set(
                      SEARCH_PARAMS_PLAYER_ID,
                      String(props.player.player.id)
                    );
                    setSearchParams(searchParams);
                  }
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={props.player.player.image}
                    sx={{ height: 32, width: 32 }}
                  />
                </ListItemAvatar>
                <Typography variant="david">
                  {props.player.player.name}
                </Typography>
                {props.editMode && (
                  <Box
                    onClick={() => {
                      if (props.player.id) {
                        deleteDepthChartPlayer.mutate({
                          depthChartPlayerName: props.player.player.name,
                          depthChartPlayerId: props.player.id,
                        });
                      }
                    }}
                    sx={{
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                  >
                    <RemoveIcon fontSize="small" sx={{ height: 12 }} />
                  </Box>
                )}
              </ListItem>
            );

            if (!usePortal) {
              return item;
            }

            // if dragging, put item in portal
            return ReactDOM.createPortal(item, portal);
          }}
        </Draggable>
      )}
    </>
  );
}
