import "./styles.scss";

import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Rating from "../../components/Rating";
import { ScoutingReport } from "../../api/types";
import { formatDateToLocalWithoutTimeYearFirst } from "../../utils/dates";
import { useScoutingReportDialogContext } from "../../contexts/ScoutingReportDialogContext";

interface ScoutingReportsTableProps {
  scoutingReports: ScoutingReport[] | undefined;
}

export default function ScoutingReportsTable(props: ScoutingReportsTableProps) {
  const { handleReportClick } = useScoutingReportDialogContext();

  const textAlign = "center";

  return (
    <TableContainer component={Paper}>
      <Table
        stickyHeader
        aria-label="simple table"
        size="small"
        sx={{ border: "0px" }}
        className="scouting-reports-table"
      >
        <TableHead>
          <TableRow>
            <TableCell align={textAlign}>Grouping</TableCell>
            <TableCell align={textAlign}>Date</TableCell>
            <TableCell align={textAlign}>Rating</TableCell>
            <TableCell align={textAlign}>Projected</TableCell>
            <TableCell align={textAlign}>Scout</TableCell>
          </TableRow>
        </TableHead>

        {props.scoutingReports?.map((report: ScoutingReport, i: number) => (
          <TableBody key={`${i}-body`}>
            <TableRow
              key={`${i}-row`}
              hover
              sx={{ cursor: "pointer", border: "0px" }}
              onClick={() => {
                handleReportClick(report);
              }}
            >
              <TableCell align={textAlign} sx={{ border: "0px" }}>
                {report.grouping}
              </TableCell>
              <TableCell align={textAlign} sx={{ border: "0px" }}>
                {formatDateToLocalWithoutTimeYearFirst(report.date)}
              </TableCell>
              <TableCell align={textAlign} sx={{ border: "0px" }}>
                <Rating value={report.rating} isProjected={false} />
              </TableCell>
              <TableCell align={textAlign} sx={{ border: "0px" }}>
                <Rating value={report.projected_rating} isProjected={true} />
              </TableCell>
              <TableCell align={textAlign} sx={{ border: "0px" }}>
                {report.scout}
              </TableCell>
              {report.stance && (
                <TableCell
                  colSpan={6}
                  sx={{ textAlign: textAlign, colspan: 3, border: "0px" }}
                >
                  {report.stance}
                </TableCell>
              )}
            </TableRow>

            {/* <TableRow
                key={`${i}-notes`}
                hover
                sx={{ cursor: "pointer", border: "0px" }}
                onClick={() => {
                  // handleReportClick(props.scoutedPlayer, report);
                }}
              ></TableRow> */}

            {props.scoutingReports &&
              i < props?.scoutingReports?.length - 1 && (
                <TableRow
                  key={`${i}-divider`}
                  sx={{ border: "0px", padding: "0" }}
                >
                  <TableCell colSpan={5} sx={{ border: "0px", padding: "0" }}>
                    <Divider variant="fullWidth" />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
}
