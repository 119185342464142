import {
  NOTES_ROUTE,
  PLAYERS_ROUTE,
  SCOUTING_REPORTS_ROUTE,
  SEARCH_ROUTE,
} from "../routes";

export const NOTES_HOTKEY = "o";
export const PLAYER_DATABASE_HOTKEY = "p";
export const SCOUTING_REPORTS_HOTKEY = "s";
export const SEARCH_HOTKEY = "/";

export const HOTKEYS = {
  notes: {
    key: "meta+" + NOTES_HOTKEY,
    tooltip: "⌘" + NOTES_HOTKEY,
    route: NOTES_ROUTE,
  },
  players: {
    key: "meta+" + PLAYER_DATABASE_HOTKEY,
    tooltip: "⌘" + PLAYER_DATABASE_HOTKEY,
    route: PLAYERS_ROUTE,
  },
  scouting_reports: {
    key: "meta+" + SCOUTING_REPORTS_HOTKEY,
    tooltip: "⌘" + SCOUTING_REPORTS_HOTKEY,
    route: SCOUTING_REPORTS_ROUTE,
  },
  search: {
    key: "meta+" + SEARCH_HOTKEY,
    tooltip: "⌘" + SEARCH_HOTKEY,
    route: SEARCH_ROUTE,
  },
};
