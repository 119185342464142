/* eslint-disable */
import {
  Avatar,
  Box,
  ListItemText,
  MenuItem,
  MenuList,
  PopoverOrigin,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DepthChartPlayer, Player } from "../../api/types";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useEffect, useRef, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PitchPlayerDraggableList from "./PitchPlayerDraggableList";
import { useCreateDepthChartPlayerMutation } from "../../api/mutations";
import { useDebounce } from "use-debounce";
import { usePlayerSearch } from "../../api/queries";
import { useThemeContext } from "../../contexts/CustomThemeContext";

const PLAYER_LIST_MAX_WIDTH = 250;
const WHITE = "#fff";

interface PlayerProps {
  depthChartId: number | undefined;
  position: string;
  updateDepthChartPlayers: (
    depthChartPlayers: DepthChartPlayer[] | undefined
  ) => void;
  players: DepthChartPlayer[] | undefined;
  topOffset: string;
  leftOffset: string;
  playerPerPosition: number;
  editMode: boolean;
}

export default function PitchPlayer(props: PlayerProps) {
  // CONTEXT
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // STATE
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [lastX, setLastX] = useState<number>();
  const [lastY, setLastY] = useState<number>();
  const [hideMenu, setHideMenu] = useState(false);
  const [playerName, setPlayerName] = useState<string>();
  const [debouncedPlayerName] = useDebounce(playerName, 300);

  const [searchForPlayer, setSearchForPlayer] = useState(false);

  const playerAvatarRadius = isScreenSmall ? 16 : 24;

  // MUTATIONS
  const createDepthChartPlayer = useCreateDepthChartPlayerMutation();

  // QUERIES
  const searchPlayerQuery = usePlayerSearch(
    { query: debouncedPlayerName || "", by: "name" },
    3,
    1
  );

  // REFS
  const ref = useRef(null);
  const playerMenuRef = useRef<HTMLDivElement>(null);

  // FUNCTIONS
  const onPlayerSearchResultClick = (player: Player) => {
    // add player to position depth chart
    let payload = {
      depth_chart_id: props.depthChartId,
      player_id: player.id,
      position: props.position,
    };

    createDepthChartPlayer.mutate({
      depthChartPlayer: payload,
      playerName: player.name,
    });

    setPlayerName("");
  };

  // USEEFFECTS
  useEffect(() => {
    setAnchorEl(ref.current);
  }, [ref]);

  useEffect(() => {
    const handleClickOutsidePlayerMenu = (event: MouseEvent | TouchEvent) => {
      if (
        playerMenuRef.current &&
        !playerMenuRef?.current.contains(event.target as Node)
      ) {
        setPlayerName("");
        setSearchForPlayer(false);
      }
    };

    document.addEventListener("mouseup", handleClickOutsidePlayerMenu, false);
    document.addEventListener("touchend", handleClickOutsidePlayerMenu, false);

    return () => {
      document.removeEventListener(
        "mouseup",
        handleClickOutsidePlayerMenu,
        false
      );
      document.removeEventListener(
        "touchend",
        handleClickOutsidePlayerMenu,
        false
      );
    };
  }, [playerMenuRef]);

  useEffect(() => {
    setSearchForPlayer(false);
  }, [props.editMode]);

  // VARIABLES
  let transformOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "center",
  };

  if (isScreenSmall) {
    if (props.position === "7" || props.position === "2") {
      transformOrigin.horizontal = "right";
    } else if (props.position === "11" || props.position === "3") {
      transformOrigin.horizontal = "left";
    }
  }

  return (
    <>
      <Draggable
        nodeRef={ref}
        onDrag={() => {
          setHideMenu(true);
        }}
        onStart={(e: DraggableEvent, data: DraggableData) => {
          setLastX(data.x);
          setLastY(data.y);
        }}
        onStop={(e: DraggableEvent, data: DraggableData) => {
          if (data.x !== lastX || data.y !== lastY) {
          } else {
          }

          setLastX(data.x);
          setLastY(data.y);
          setHideMenu(false);
        }}
      >
        <Tooltip
          key={`player-position-${props.position}-tooltip`}
          placement="top"
          title="Click and hold to drag"
        >
          <Avatar
            ref={ref}
            aria-describedby={`player-${props.position}-popover`}
            sx={{
              position: "absolute",
              cursor: "grab",
              backgroundColor: theme.palette.primary.main,
              width: playerAvatarRadius,
              height: playerAvatarRadius,
              top: props.topOffset,
              left: props.leftOffset,
            }}
          >
            <Typography variant="button">{props.position}</Typography>
          </Avatar>
        </Tooltip>
      </Draggable>
      {/* Hide menu when avatar is actively being dragged */}
      {!hideMenu && (
        <>
          <Popper
            id={`player-${props.position}-popover`}
            open={true}
            anchorEl={anchorEl}
            placement="bottom"
            modifiers={[
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >
            {props.depthChartId && (
              <Box sx={{ width: 200 }}>
                {props.players && props.players.length > 0 && (
                  <PitchPlayerDraggableList
                    depthChartId={props.depthChartId}
                    position={props.position}
                    updateDepthChartPlayers={props.updateDepthChartPlayers}
                    players={props.players}
                    editMode={props.editMode}
                  />
                )}
                {props.editMode && !searchForPlayer && (
                  <Box
                    onClick={() => {
                      setSearchForPlayer(true);
                    }}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <AddCircleOutlineIcon
                      sx={{
                        marginTop: 0.5,
                        height: 20,
                        width: 20,
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                )}

                {props.editMode && searchForPlayer && (
                  <Box ref={playerMenuRef}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingTop: 0.5,
                      }}
                    >
                      <TextField
                        placeholder="Add Player"
                        inputRef={(inputRef) => inputRef && inputRef.focus()}
                        value={playerName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setPlayerName(event.target.value);
                        }}
                        sx={{
                          backgroundColor: "white",
                          width: 200,
                          borderRadius: 1,
                        }}
                        inputProps={{
                          sx: {
                            fontSize: 14,
                            padding: 1,
                            borderRadius: 1,
                            borderColor: theme.palette.primary.main,
                            borderStyle: "solid",
                            borderWidth: 1,
                          },
                        }}
                      ></TextField>
                    </Box>

                    <MenuList
                      disablePadding
                      dense
                      sx={{
                        backgroudColor: "white !important",
                        borderRadius: 2,
                      }}
                    >
                      {searchPlayerQuery.data?.data.map(
                        (p: Player, i: number) => {
                          return (
                            <MenuItem
                              key={i}
                              onClick={() => {
                                onPlayerSearchResultClick(p);
                              }}
                              sx={{
                                backgroundColor: "white !important",
                                padding: 0.5,
                              }}
                            >
                              <Box>
                                <Avatar
                                  src={p.image}
                                  sx={{ height: 32, width: 32 }}
                                />
                              </Box>

                              <ListItemText
                                sx={{ paddingLeft: 1 }}
                                primary={
                                  <Stack
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Typography variant="david" noWrap={true}>
                                      {p.name}
                                    </Typography>
                                  </Stack>
                                }
                                secondary={
                                  <Stack
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Typography noWrap variant="david">
                                      {p.team}
                                    </Typography>
                                  </Stack>
                                }
                              />
                            </MenuItem>
                          );
                        }
                      )}
                    </MenuList>
                  </Box>
                )}
              </Box>
            )}
          </Popper>
        </>
      )}
    </>
  );
}
