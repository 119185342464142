import { FIRST_TEAM, PLAYERS_PERMISSION, SUPERUSER } from "../constants";
/* eslint-disable */
import {
  NOTES_ROUTE,
  PLAYERS_ROUTE,
  SCOUTING_REPORTS_ROUTE,
  SEARCH_ROUTE,
} from "../routes";

import DepthChartMenu from "./DepthChartMenu";
import { Folder } from "../api/types";
import { HOTKEYS } from "./HotKeys";
import ListsMenu from "./ListsMenu";
import { TreeItem } from "./TreeItem";
import TreeItemLink from "./TreeItemLink";
import { useTeamContext } from "../contexts/TeamContext";
import { useUserContext } from "../contexts/UserContext";

export default function ScoutingMenu(props: { folders: Folder[] | null }) {
  //   CONTEXT
  const { roles, permissions } = useUserContext();
  const { team } = useTeamContext();

  return (
    <TreeItem itemId="scouting" label="SCOUTING">
      {team === FIRST_TEAM &&
        (roles.includes(SUPERUSER) ||
          permissions.includes(PLAYERS_PERMISSION)) && (
          <TreeItemLink
            to={PLAYERS_ROUTE}
            label="PLAYERS"
            nested
            tooltipTitle={HOTKEYS["players"].tooltip}
          />
        )}

      {/* <TreeItemLink to={SCOUTING_DEPTH_ROUTE} label="DEPTH CHART" nested /> */}

      {/* <DepthChartMenu /> */}

      <ListsMenu folders={props.folders} />

      <TreeItemLink
        to={NOTES_ROUTE}
        label="NOTES"
        nested
        tooltipTitle={HOTKEYS["notes"].tooltip}
      />

      <TreeItemLink
        to={SCOUTING_REPORTS_ROUTE}
        label="REPORTS"
        nested
        tooltipTitle={HOTKEYS["scouting_reports"].tooltip}
      />

      {/* <TreeItemLink
        to={SCOUTING_REPORTS_ROUTE}
        label="REPORTS"
        nested
        tooltipTitle={HOTKEYS["scouting_reports"].tooltip}
      /> */}

      {/* <TreeItemLink
        to={SEARCH_ROUTE}
        label="SEARCH"
        nested
        tooltipTitle={HOTKEYS["search"].tooltip}
      /> */}

      {/* <TreeItemLink
        to={SCOUTING_SCHEDULING}
        label="SCHEDULING"
        nested
      />

      <TreeItemLink
        to={SCOUTING_SHORTLIST_ROUTE}
        label="SHORT LIST"
        nested
      />

      <TreeItemLink
        to={SCOUTING_TEAM_OF_THE_WEEK_ROUTE}
        label="TEAM OF THE WEEK"
        nested
      /> */}
    </TreeItem>
  );
}
