import { Box, Divider } from "@mui/material";

import { ANALYTICS_ROLE } from "../../constants";
import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import AnalyticsDataGrid from "./AnalyticsDataGrid";
import { GridRowGroupingInitialState } from "@mui/x-data-grid-premium";
import { PlayerTableViewSwitch } from "../../components/PlayerTableViewSwitch";
import ScoutingDataGrid from "./ScoutingDataGrid";
import useLocalStorage from "react-use-localstorage";
import { useUserContext } from "../../contexts/UserContext";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

interface PlayersProps {
  hideToolbar?: boolean;
  hideFooter?: boolean;
  initialRowGrouping?: GridRowGroupingInitialState | undefined;
}

const VIEW_MODE_ANALYTICS = "analytics";
const VIEW_MODE_SCOUTING = "scouting";

export default function Players(props: PlayersProps) {
  // CONTEXT
  const { height } = useWindowSizeContext();
  const { primaryRole } = useUserContext();

  // VARIABLES
  const adjHeight = height - APPBAR_HEIGHT_AS_NUM;

  // STATES
  const [viewMode, setViewMode] = useLocalStorage(
    "players_view_mode",
    VIEW_MODE_SCOUTING
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: adjHeight,
        maxHeight: adjHeight,
      }}
    >
      {primaryRole === ANALYTICS_ROLE && (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 1 }}>
            <PlayerTableViewSwitch
              viewMode={viewMode}
              setViewMode={(newViewMode: string) => {
                setViewMode(newViewMode);
              }}
            />
          </Box>
          <Divider />
        </>
      )}

      {viewMode === VIEW_MODE_ANALYTICS && (
        <AnalyticsDataGrid
          hideToolbar={props.hideToolbar}
          hideFooter={props.hideFooter}
        />
      )}
      {viewMode === VIEW_MODE_SCOUTING && <ScoutingDataGrid />}
    </Box>
  );
}
