import { Dispatch, SetStateAction, useState } from "react";
import {
  Grid,
  LinearProgress,
  MenuList,
  TextField,
  useMediaQuery,
} from "@mui/material";

import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Player } from "../api/types";
import PlayerMiniCard from "./PlayerMiniCard";
import { useDebounce } from "use-debounce";
import { usePlayerSearch } from "../api/queries";
import { useThemeContext } from "../contexts/CustomThemeContext";

interface PlayerQuickSearchProps {
  player: Player;
  playerInComparison: Player | undefined;
  setPlayerInComparison: Dispatch<SetStateAction<Player | undefined>>;
}

export default function PlayerQuickSearch(props: PlayerQuickSearchProps) {
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [playerName, setPlayerName] = useState<string | undefined>();
  const [debouncedPlayerName] = useDebounce(playerName, 700);

  const onChangePlayerName = (newValue: string) => {
    setPlayerName(newValue);
  };

  const searchPlayerQuery = usePlayerSearch(
    {
      query: debouncedPlayerName || "",
      by: "name",
    },
    3,
    1
  );

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: isScreenSmall ? "100%" : "400px",
      }}
    >
      {!props.playerInComparison && (
        <Grid xs={12} item sx={{ width: "100%" }}>
          <TextField
            id="report-dialog-player-name-select"
            color="secondary"
            label="compare to player"
            name="player_name"
            value={playerName}
            variant="outlined"
            fullWidth
            onChange={(e: any) => onChangePlayerName(e.target.value)}
            size="medium"
            margin="dense"
            InputProps={{
              startAdornment: (
                <PeopleOutlineIcon
                  fontSize="small"
                  sx={{ fill: "currentColor", marginRight: 1 }}
                />
              ),
              sx: {
                height: "54px",
              },
            }}
            sx={{
              height: "54px",
              marginTop: 0.5,
            }}
          />
        </Grid>
      )}

      <Grid xs={12} item sx={{ width: "100%" }}>
        {(searchPlayerQuery.isFetching || searchPlayerQuery.isLoading) && (
          <LinearProgress color="secondary" />
        )}
      </Grid>

      <Grid xs={12} item sx={{ width: "100%" }}>
        <MenuList disablePadding dense sx={{ width: "100%" }}>
          {props.playerInComparison && (
            <PlayerMiniCard
              player={props.playerInComparison}
              playerInComparison={props.playerInComparison}
              setPlayerInComparison={props.setPlayerInComparison}
            />
          )}
          {!props.playerInComparison &&
            searchPlayerQuery.data?.data.map((p: Player, i: number) => {
              if (p?.id) {
                return (
                  <PlayerMiniCard
                    player={p}
                    playerInComparison={props.playerInComparison}
                    setPlayerInComparison={props.setPlayerInComparison}
                  />
                );
              }

              return null;
            })}
        </MenuList>
      </Grid>
    </Grid>
  );
}
