import { Avatar, IconButton, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback } from "react";

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Player } from "../api/types";

interface PlayerMiniCardProps {
    player: Player
    playerInComparison: Player | undefined
    setPlayerInComparison: Dispatch<SetStateAction<Player | undefined>>
}

export default function PlayerMiniCard(props: PlayerMiniCardProps) {
    const isSelected = props.player.id === props.playerInComparison?.id;

    const onPlayerSearchResultClick = useCallback((p: Player) => {
        props.setPlayerInComparison(p);
      },
      [props]
    );

    return (
        <MenuItem
            dense
            onClick={() => onPlayerSearchResultClick(props.player)}
            selected={isSelected}
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: 1,
              width: "100%",
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: .5,
              paddingRight: .5,
            }}
        >
            {isSelected && 
              <ListItemIcon>
                <IconButton 
                  onClick={(event) => {
                    event.stopPropagation();
                    props.setPlayerInComparison(undefined);
                  }
                }>
                    <CancelOutlinedIcon color="secondary" />
                </IconButton>
              </ListItemIcon>
            }

            <ListItemAvatar>
              <Avatar src={props.player.image} />
            </ListItemAvatar>

            <ListItemText
              primary={
                <Stack
                  direction="row"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography noWrap variant="subtitle2">
                    {props.player.name}
                  </Typography>

                  <Typography noWrap variant="caption">
                    {props.player.age}
                  </Typography>
                </Stack>
            }
            secondary={
              <Stack
                direction="row"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography noWrap variant="caption">
                  {props.player.team}
                </Typography>
                <Typography noWrap variant="caption">
                  {props.player.nationality}
                </Typography>
              </Stack>
            }
          />
        </MenuItem>
    )
}
