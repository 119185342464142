/* eslint-disable */
import { Box, Fab, Tooltip, useMediaQuery } from "@mui/material";
import { DepthChart, DepthChartPlayer } from "../../api/types";
import { useEffect, useState } from "react";

import { APPBAR_HEIGHT_AS_NUM } from "../../app_bar/Toolbar";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { FIRST_TEAM } from "../../constants";
import PitchPlayer from "./PitchPlayer";
import rfdc from "rfdc";
import { useDepthChart } from "../../api/queries";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useUserContext } from "../../contexts/UserContext";

const deepCopy = rfdc();

export const CREATE_ACTION = "create";
export const UPDATE_ACTION = "update";

const GREEN = "#4c7";
const LIGHT_GREEN = "#5d8";
const PLAYERS_PER_POSITION = 5;

interface PitchProps {}

export default function Pitch(props: PitchProps) {
  // CONTEXT
  const { theme } = useThemeContext();
  const { primaryTeam } = useUserContext();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // MUTATIONS

  // QUERIES
  const getDepthChart = useDepthChart(1);

  // PITCH DIMENSIONS
  // ASSUME PITCH IS 90 x 120 (width x height): so a ratio of 3 / 4

  // const EFFECTIVE_PITCH_WIDTH = fullScreen ? "100vw" : "80vw";
  const EFFECTIVE_PITCH_WIDTH = "100vw";
  const EFFECTIVE_PITCH_HEIGHT = `calc(100vh - ${APPBAR_HEIGHT_AS_NUM}px)`;

  // BORDERS
  const BORDER_WIDTH = "4px";
  const BORDER = `${BORDER_WIDTH} solid white`;

  // SIX YARD BOX DIMENSIONS
  const SIX_YARD_BOX_HEIGHT_RATIO = "6 / 120";
  const SIX_YARD_BOX_WIDTH_RATIO = "20 / 90";
  const SIX_YARD_BOX_WIDTH = `calc((${SIX_YARD_BOX_WIDTH_RATIO}) * ${EFFECTIVE_PITCH_WIDTH})`;
  const SIX_YARD_BOX_HEIGHT = `calc((${SIX_YARD_BOX_HEIGHT_RATIO}) * ${EFFECTIVE_PITCH_HEIGHT})`;
  const SIX_YARD_BOX_OFFSET = `calc((${EFFECTIVE_PITCH_WIDTH} - ${SIX_YARD_BOX_WIDTH} - 8px) / 2)`;

  // EIGHTEEN YARD BOX
  const EIGHTTEN_HEIGHT_RATIO = "18 / 120";
  const EIGHTEEN_HEIGHT = `calc(${EIGHTTEN_HEIGHT_RATIO} * ${EFFECTIVE_PITCH_HEIGHT})`;
  const EIGHTEEN_WIDTH_RATIO = fullScreen ? "55 / 90" : "44 / 90";
  const EIGHTEEN_WIDTH = `calc(${EIGHTEEN_WIDTH_RATIO} * ${EFFECTIVE_PITCH_WIDTH})`;
  const EIGHTEEN_OFFSET = `calc((${EFFECTIVE_PITCH_WIDTH} - ${EIGHTEEN_WIDTH} - 8px) / 2)`;

  // HALF CIRCLES
  const HALF_CIRCLE_RADIUS_RATIO_START = fullScreen ? "15 / 120" : "17 / 120";
  const HALF_CIRCLE_RADIUS_RATIO_STOP = fullScreen
    ? "15.5 / 120"
    : "17.5 / 120";
  const HALF_CIRCLE_RADIUS_START = `calc(${HALF_CIRCLE_RADIUS_RATIO_START} * ${EFFECTIVE_PITCH_HEIGHT})`;
  const HALF_CIRCLE_RADIUS_STOP = `calc(${HALF_CIRCLE_RADIUS_RATIO_STOP} * ${EFFECTIVE_PITCH_HEIGHT})`;

  // STATE
  const [editMode, setEditMode] = useState<boolean>(false);
  const [depthChart, setDepthChart] = useState<DepthChart>();

  // USEEFFECTS
  useEffect(() => {
    if (getDepthChart.isFetched) {
      setDepthChart(getDepthChart.data);
    }
  }, [getDepthChart.isFetched, getDepthChart.data]);

  // FUNCTIONS
  // used to maintain the visual order of the ordered players while they update in the backend
  const updateDepthChartPlayers = (
    updatedDepthChartPlayers: DepthChartPlayer[] | undefined
  ) => {
    let updatedDepthChart = deepCopy(depthChart);

    if (updatedDepthChart) {
      updatedDepthChartPlayers?.forEach((udcp) => {
        let playerToUpdate = updatedDepthChart?.depth_chart_players.find(
          (dcp) => dcp.id === udcp.id
        );
        if (playerToUpdate) {
          playerToUpdate.rank = udcp.rank;
        }
      });

      setDepthChart(updatedDepthChart);
    }
  };

  return (
    // https://stackoverflow.com/questions/68295881/resize-soccer-pitch-to-fill-screen-react-css
    // PITCH
    <>
      {primaryTeam == FIRST_TEAM &&
        ((!editMode && (
          <Tooltip
            disableFocusListener
            key="edit-depth-chart-tooltip"
            title="EDIT DEPTH CHART"
          >
            <Fab
              color="secondary"
              aria-label="edit"
              size="small"
              onClick={() => {
                setEditMode(true);
              }}
              sx={{
                color: theme.palette.primary.contrastText,
                bgcolor: theme.palette.primary.main,
                "&:hover": {
                  bgcolor: theme.palette.primary.main,
                },
                position: "absolute",
                top: 75,
                right: 25,
              }}
            >
              <EditIcon fontSize="small" />
            </Fab>
          </Tooltip>
        )) ||
          (editMode && (
            <Tooltip
              disableFocusListener
              key="stop-edit-depth-chart-tooltip"
              title="STOP EDITING"
            >
              <Fab
                color="secondary"
                aria-label="edit"
                size="small"
                onClick={() => {
                  setEditMode(false);
                }}
                sx={{
                  color: theme.palette.primary.contrastText,
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    bgcolor: theme.palette.primary.main,
                  },
                  position: "absolute",
                  top: 75,
                  right: 25,
                }}
              >
                <ClearIcon fontSize="small" />
              </Fab>
            </Tooltip>
          )))}

      <Box
        sx={{
          background: GREEN,
          position: "absolute",
          width: EFFECTIVE_PITCH_WIDTH,
          height: EFFECTIVE_PITCH_HEIGHT,
          // border: "0.5vmin solid white",
          backgroundImage: `
                    radial-gradient(circle at 0% 0%, transparent 1%, white 0 1.5%, transparent 0),
                    radial-gradient(circle at 100% 0%, transparent 1%, white 0 1.5%, transparent 0), 
                    radial-gradient(circle at 0% 100%, transparent 1%, white 0 1.5%, transparent 0), 
                    radial-gradient(circle at 100% 100%, transparent 1%, white 0 1.5%, transparent 0), 
                    radial-gradient(circle at 50% 50%, white 1%, transparent 0), 
                    radial-gradient(circle at 50% 50%, transparent 14%, white 0 14.75%, transparent 0), 
                    radial-gradient(circle at 50% 5%, transparent ${HALF_CIRCLE_RADIUS_START}, white 0 ${HALF_CIRCLE_RADIUS_STOP}, transparent 0), 
                    radial-gradient(circle at 50% 95%, transparent ${HALF_CIRCLE_RADIUS_START}, white 0 ${HALF_CIRCLE_RADIUS_STOP}, transparent 0), 
                    linear-gradient(to top, transparent 49.75%, white 0 50.25%, transparent 0), 
                    repeating-linear-gradient(to top, ${GREEN} 0 10%, ${LIGHT_GREEN} 0 20%)
                `,
          paddingBottom: 0,
        }}
      >
        {/* Top 18 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: "0%",
            left: EIGHTEEN_OFFSET,
            width: EIGHTEEN_WIDTH,
            height: EIGHTEEN_HEIGHT,
            border: BORDER,
            borderTop: "0",
          }}
        />
        {/* Top 6 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: "0%",
            left: SIX_YARD_BOX_OFFSET,
            width: SIX_YARD_BOX_WIDTH,
            height: SIX_YARD_BOX_HEIGHT,
            border: BORDER,
            borderTop: "0",
          }}
        />
        {/* Bottom 18 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: `calc(${EFFECTIVE_PITCH_HEIGHT} - ${EIGHTEEN_HEIGHT} - 4px)`,
            left: EIGHTEEN_OFFSET,
            width: EIGHTEEN_WIDTH,
            height: EIGHTEEN_HEIGHT,
            border: BORDER,
            borderBottom: "0",
          }}
        />
        {/* Bottom 6 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: `calc(${EFFECTIVE_PITCH_HEIGHT} - ${SIX_YARD_BOX_HEIGHT} - 4px)`,
            left: SIX_YARD_BOX_OFFSET,
            width: SIX_YARD_BOX_WIDTH,
            height: SIX_YARD_BOX_HEIGHT,
            border: BORDER,
            borderBottom: "0",
          }}
        />
        <>
          {/* Number 1 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"1"}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "1";
            })}
            updateDepthChartPlayers={updateDepthChartPlayers}
            topOffset="70%"
            leftOffset={fullScreen ? "46%" : "49%"}
            playerPerPosition={PLAYERS_PER_POSITION - 1}
            editMode={editMode}
          />
          {/* Number 2 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"2"}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "2";
            })}
            updateDepthChartPlayers={updateDepthChartPlayers}
            topOffset="60%"
            leftOffset={fullScreen ? "86%" : "88%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 3 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"3"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "3";
            })}
            topOffset="60%"
            leftOffset={fullScreen ? "6%" : "8%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 4 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"4"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "4";
            })}
            topOffset="65%"
            leftOffset={fullScreen ? "66%" : "68%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 5 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"5"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "5";
            })}
            topOffset="65%"
            leftOffset={fullScreen ? "26%" : "28%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 6 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"6"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "6";
            })}
            topOffset="35%"
            leftOffset={fullScreen ? "46%" : "49%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 7 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"7"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "7";
            })}
            topOffset="15%"
            leftOffset={fullScreen ? "86%" : "88%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 8 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"8"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "8";
            })}
            topOffset="25%"
            leftOffset={fullScreen ? "66%" : "68%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 9 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"9"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "9";
            })}
            topOffset="0%"
            leftOffset={fullScreen ? "46%" : "49%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 10 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"10"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "10";
            })}
            topOffset="25%"
            leftOffset={fullScreen ? "26%" : "28%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Number 11 */}
          <PitchPlayer
            depthChartId={depthChart?.id}
            position={"11"}
            updateDepthChartPlayers={updateDepthChartPlayers}
            players={depthChart?.depth_chart_players.filter((p) => {
              return p.position === "11";
            })}
            topOffset="15%"
            leftOffset={fullScreen ? "6%" : "8%"}
            playerPerPosition={PLAYERS_PER_POSITION}
            editMode={editMode}
          />
          {/* Complete List for Selected Position */}
          {/* {!fullScreen &&
              props.grouping === SCOUTING_REPORT_GROUP_COLLEGE && (
                <AllPlayer
                  grouping={props.grouping}
                  position={position}
                  topOffset="10%"
                  leftOffset="-14%"
                  reports={academyScoutingReports.data}
                  setPosition={setPosition}
                  editMode={props.editMode}
                  setScoutedPlayerInContext={props.setScoutedPlayerInContext}
                  setScoutedPlayerProfileModalOpen={
                    props.setScoutedPlayerProfileModalOpen
                  }
                />
              )} */}
        </>
      </Box>
    </>
  );
}
