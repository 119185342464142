import {
  Box,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip as MuiTooltip,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { EMPTY_FIELD, SEARCH_PARAMS_REPORT_ID } from "../../../constants";

import CloseButton from "../../../components/buttons/CloseButton";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import { DialogField } from "./DialogField";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Rating from "../../../components/Rating";
import ReactMarkdown from "react-markdown";
import { ScoutingReport } from "../../../api/types";
import { ScoutingReportActionsMenu } from "./ScoutingReportActionsMenu";
import { formatDateToLocalWithoutTimeYearFirst } from "../../../utils/dates";
import { useDeleteScoutingReportMutation } from "../../../api/mutations";
import { useSearchParams } from "react-router-dom";
import { useThemeContext } from "../../../contexts/CustomThemeContext";

export interface QueryScoutingReportDialogProps {
  open: boolean;
  report: ScoutingReport | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
  openMutateDialog: Dispatch<SetStateAction<boolean>>;
  readOnly: boolean;
}

export default function QueryScoutingReportDialog(
  props: QueryScoutingReportDialogProps
) {
  // CONTEXT
  const [searchParams, setSearchParams] = useSearchParams();
  const { theme } = useThemeContext();

  // STATE
  const [stanceExpanded, setStanceExpanded] = useState(true);
  const [extendedPlayerInfoExpanded, setExtendedPlayerInfoExpanded] =
    useState(false);
  const [deleteReportModalOpen, setDeleteReportModalOpen] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const actionsMenuOpen = Boolean(actionsAnchorEl);

  // eslint-disable-next-line
  const [maxWidth, _] = useState<DialogProps["maxWidth"]>("sm");

  // VARIABLES
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // MUTATIONS
  const deleteScoutingReportMutation = useDeleteScoutingReportMutation();

  // FUNCTIONS
  const handleActionsMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
  };

  return (
    <>
      {props.report && (
        <ConfirmationDialog
          key={`${props.report.id}-confirmation-dialog`}
          open={deleteReportModalOpen}
          title={"Are you sure you want to delete this scouting report?"}
          yesAction={() => {
            deleteScoutingReportMutation.mutate(props?.report?.id);
            setDeleteReportModalOpen(false);
            props.setOpen(false);
            setSearchParams(undefined);
          }}
          noAction={() => {
            setDeleteReportModalOpen(false);
          }}
        />
      )}

      <Dialog
        fullScreen={isScreenSmall ? true : false}
        fullWidth={true}
        maxWidth={maxWidth}
        onClose={(event: object, reason: string) => {
          if (reason === "escapeKeyDown" || reason === "backdropClick") {
            props.setOpen(false);
            setSearchParams(undefined);
          }
        }}
        open={props.open}
      >
        <DialogContent sx={{ padding: 0 }}>
          <DialogTitle
            key={"query-scouting-report-dialog-title"}
            sx={{ textAlign: "center", padding: 1 }}
          >
            <Stack>
              <Typography variant="h4">
                {`${props.report?.first_name} ${props.report?.last_name}`}
              </Typography>

              <Stack
                direction="row"
                display="flex"
                justifyContent="space-evenly"
              >
                <Rating
                  value={props.report?.rating ? props.report.rating : 0}
                  includeLabel
                />
                <Rating
                  value={
                    props.report?.projected_rating
                      ? props.report.projected_rating
                      : 0
                  }
                  includeLabel
                  isProjected
                />
              </Stack>
            </Stack>
          </DialogTitle>

          <CloseButton
            position="absolute"
            top={5}
            left={5}
            onClick={() => {
              searchParams.delete(SEARCH_PARAMS_REPORT_ID);
              setSearchParams(searchParams);
              props.setOpen(false);
            }}
          />

          {!props.readOnly && (
            <Box position="absolute" top={5} right={15}>
              <ScoutingReportActionsMenu
                anchorEl={actionsAnchorEl}
                open={actionsMenuOpen}
                report={props.report}
                handleActionMenuClose={handleActionsMenuClose}
                openMutateDialog={() => {
                  props.openMutateDialog(true);
                  handleActionsMenuClose();
                  props.setOpen(false);
                }}
                setOpen={props.setOpen}
                setDeleteReportModalOpen={() => {
                  setDeleteReportModalOpen(true);
                  handleActionsMenuClose();
                  props.setOpen(false);
                }}
              />

              <MuiTooltip
                key={`${props?.report?.id}-report-actions-tooltip`}
                title="Actions"
              >
                <IconButton
                  key={`${props?.report?.id}-actions-button`}
                  edge="end"
                  aria-label="expand"
                  aria-controls={actionsMenuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={actionsMenuOpen ? "true" : undefined}
                  onClick={handleActionsMenuButtonClick}
                >
                  <MoreVertIcon
                    key={`${props?.report?.id}-player-actions-menu-vert-icon`}
                    fontSize="small"
                    color="primary"
                  />
                </IconButton>
              </MuiTooltip>
            </Box>
          )}

          {/* DATE | SCOUT */}
          <Stack direction="column" mt={1} mb={1}>
            <Stack
              alignItems="left"
              direction="row"
              justifyContent="space-evenly"
              sx={{ display: "flex", flexGrow: 1 }}
            >
              <DialogField
                label="DATE"
                value={
                  props?.report?.date &&
                  formatDateToLocalWithoutTimeYearFirst(props.report.date)
                }
                width="50%"
              />

              <DialogField
                label="SCOUT"
                value={props?.report?.scout}
                width="50%"
              />
            </Stack>
          </Stack>

          {/* GROUP | RATING */}
          <Stack direction="column" mt={1} mb={1}>
            <Stack
              alignItems="left"
              direction="row"
              justifyContent="space-evenly"
              spacing={1}
              sx={{ display: "flex", flexGrow: 1 }}
            >
              <DialogField
                label="GROUP"
                value={props?.report?.grouping || EMPTY_FIELD}
                width="50%"
              />

              {/* <DialogField
                label="LOCATION"
                value={props?.report?.location || EMPTY_FIELD}
                width="50%"
              /> */}
            </Stack>

            {/* CLUB | POSITION */}
            {/* <Stack
              alignItems="left"
              direction="row"
              justifyContent="space-evenly"
              sx={{ display: "flex", flexGrow: 1 }}
              mt={1}
              mb={1}
            >
              <DialogField
                label="TEAM"
                value={props?.report?.club || EMPTY_FIELD}
                width="50%"
              />

              <Box sx={{ width: "50%" }} p={1}>
                <Typography variant="subtitle2" align="center">
                  POSITION
                </Typography>
                <Typography variant="subtitle1" align="center">
                  {props?.report?.position
                    ? SCOUTING_POSITIONS[Number(props?.report?.position) - 1]
                    : EMPTY_FIELD}
                </Typography>
              </Box>
            </Stack> */}

            {/* BIRTH YEAR | AGE */}
            {/* <Stack
              alignItems="left"
              direction="row"
              justifyContent="space-evenly"
              sx={{ display: "flex", flexGrow: 1 }}
            >
              <DialogField
                label="AGE"
                value={
                  props?.report?.birth_year
                    ? currentYear - parseInt(props?.report?.birth_year)
                    : EMPTY_FIELD
                }
                width="50%"
              />

              <DialogField
                label="BIRTH YEAR"
                value={
                  props?.report?.birth_year
                    ? props?.report?.birth_year
                    : EMPTY_FIELD
                }
                width="50%"
              />
            </Stack> */}
          </Stack>

          <Divider sx={{ marginTop: 2, marginBottom: 2 }}>
            <Chip
              label="ADDITIONAL INFO"
              color="primary"
              icon={
                extendedPlayerInfoExpanded ? (
                  <ExpandMoreIcon />
                ) : (
                  <ExpandLessIcon />
                )
              }
              onClick={() =>
                setExtendedPlayerInfoExpanded(!extendedPlayerInfoExpanded)
              }
            />
          </Divider>

          <Collapse
            key={"additional-player-info-collapse"}
            in={extendedPlayerInfoExpanded}
            timeout="auto"
            unmountOnExit
          >
            <Stack
              direction="column"
              divider={<Divider orientation="vertical" flexItem />}
            >
              {/* <Stack
                alignItems="left"
                direction="row"
                justifyContent="space-evenly"
                sx={{ display: "flex", flexGrow: 1 }}
              > */}
              {/* <DialogField
                  label="AFFILIATION"
                  value={props?.report?.affiliation || EMPTY_FIELD}
                  width="33%"
                /> */}

              {/* <DialogField
                  label="KIT NUMBER"
                  value={
                    (props?.report?.kit_number === 97
                      ? `${props.report.kit_number} (default)`
                      : props?.report?.kit_number) || EMPTY_FIELD
                  }
                  width="33%"
                />

                <DialogField
                  label="FOOT"
                  value={props?.report?.foot || EMPTY_FIELD}
                  width="33%"
                /> */}
              {/* </Stack> */}

              <Stack
                direction="row"
                justifyContent="space-evenly"
                mt={2}
                sx={{ display: "flex", flexGrow: 1 }}
              >
                <DialogField
                  label="OPPONENT"
                  value={props?.report?.opponent || EMPTY_FIELD}
                  width="33%"
                />

                <DialogField
                  label="RESISTANCE"
                  value={props?.report?.opponent_resistance || EMPTY_FIELD}
                  width="33%"
                />

                {/* <DialogField
                  label="SEPARATOR"
                  value={props?.report?.separator || EMPTY_FIELD}
                  width="33%"
                /> */}
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-evenly"
              mt={2}
              sx={{ display: "flex", flexGrow: 1 }}
            >
              <DialogField
                label="POSITION"
                value={props?.report?.position || EMPTY_FIELD}
                width="50%"
              />
            </Stack>
          </Collapse>

          <Divider sx={{ marginTop: 2, marginBottom: 2 }}>
            <Chip
              label="STANCE"
              color="primary"
              icon={stanceExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              onClick={() => setStanceExpanded(!stanceExpanded)}
            />
          </Divider>

          <Collapse
            key={"stance-collapse"}
            in={stanceExpanded}
            timeout="auto"
            unmountOnExit
            sx={{
              padding: 1,
            }}
          >
            <ReactMarkdown
              children={props?.report?.stance ? props.report.stance : ""}
              components={{
                h1: ({ node, ...props }) => (
                  // eslint-disable-next-line jsx-a11y/heading-has-content
                  <h3 style={{ textAlign: "center" }} {...props} />
                ),
                hr: ({ node, ...props }) => (
                  <hr style={{ opacity: 0.4 }} {...props} />
                ),
              }}
            />
          </Collapse>
        </DialogContent>
      </Dialog>
    </>
  );
}
