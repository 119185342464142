/* eslint-disable */
import TableauDashboard, {
  TableauDashboardParams,
} from "../../components/TableauDashboard";

import Pitch from "./Pitch";
import { ScoutedPlayer } from "../../api/types";
import { SetStateAction } from "react";

const WORKBOOK_URL =
  "https://prod-useast-b.online.tableau.com/t/waldenanalytics/views/DepthChart/DepthChart";

interface DepthChartProps {
  competition?: string;
  team?: string;
}

const DepthChart = (props: DepthChartProps) => {
  return <Pitch />;
};

export default DepthChart;
