import { Folder, PlayerList } from "../api/types";
import { Stack, Tooltip, Typography } from "@mui/material";

import { LIST } from "./ItemTypes";
import { ListActionMenu } from "./ListActionMenu";
import { useDrag } from "react-dnd";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useUpdateListFolderMutation } from "../api/mutations";

interface DropResult {
  id: number;
}

interface ListLabelProps {
  folder: Folder;
  list: PlayerList;
}

export default function ListLabel(props: ListLabelProps) {
  // MUTATIONS
  const updateListFolder = useUpdateListFolderMutation();
  const { email } = useOktaAuthContext();

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: LIST,
    item: { id: props.list.id },
    canDrag: () => {
      return props.list.owner === email;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        // alert(`You dropped list: ${item.id} into folder: ${dropResult.id}!`);
        props.list.folder_id = dropResult.id;
        updateListFolder.mutate(props.list);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div
      key={`${props.folder.id}-${props.list.id}-${props.list.name}-list-label-div`}
      ref={dragRef}
      style={{ opacity: isDragging ? 0.5 : 1, width: "100%" }}
    >
      <Stack
        key={`${props.folder.id}-${props.list.id}-${props.list.name}-treeview-tree-item-list-label`}
        direction="row"
        display="flex"
        justifyContent="space-between"
        sx={{
          cursor: props.list.owner === email ? "move" : "pointer",
          ml: 0.5,
        }}
      >
        <Tooltip
          title={props.list.name}
          placement="right"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 26],
                  },
                },
              ],
            },
          }}
        >
          <Typography
            key={`${props.folder.id}-${props.list.id}-${props.list.name}-list-label-text`}
            draggable
            variant="caption"
            noWrap
            mt="auto"
          >
            {props.list.name}
          </Typography>
        </Tooltip>

        {props.folder.id >= 0 && <ListActionMenu list={props.list} />}
      </Stack>
    </div>
  );
}
