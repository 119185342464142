import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridPinnedColumnFields,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GET_LIST, GET_PLAYERS } from "../../api/keys";
import {
  SEARCH_PARAMS_LIST_ID,
  SEARCH_PARAMS_PLAYER_ID,
} from "../../constants";
import { SEVERITY_ERROR, useAlertContext } from "../../contexts/AlertContext";
import ScoutingDataGridColDefs, {
  displayedByDefault,
  hiddenByDefault,
} from "./ScoutingDataGridColDefs";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useList, usePlayerDatabase } from "../../api/queries";

import { EmptyStateOverlay } from "../../components/EmptyStateOverlay";
import MutatePlayerDialog from "../../components/dialogs/MutatePlayerDialog";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Player } from "../../api/types";
import { getUniqueKeyPlayer } from "../../utils/uniqueKey";
import { useDebounce } from "use-debounce";
import { useListContext } from "../../contexts/ListContext";
import useLocalStorage from "react-use-localstorage";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

interface ScoutingDataGridProps {
  hideToolbar?: boolean;
  hideFooter?: boolean;
}

const VIEW_MODE_SCOUTING = "scouting";

const DEFAULT_DENSITY = "compact";
const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 30;

const initialColumnVisibilityModel: GridColumnVisibilityModel = {};
displayedByDefault.map(
  (col: GridColDef) => (initialColumnVisibilityModel[col.field] = true)
);
hiddenByDefault.map(
  (col: GridColDef) => (initialColumnVisibilityModel[col.field] = false)
);

const initialGridState = {
  columns: {
    columnVisibilityModel: initialColumnVisibilityModel,
  },
  density: DEFAULT_DENSITY,
  pagination: {
    paginationModel: { pageSize: DEFAULT_PAGE_SIZE, page: DEFAULT_PAGE },
  },
  pinnedColumns: { left: [], right: [] },
} as GridInitialState;

// Moving functional component to outside main component remedies issue of search bar unfocusing each time
// input is updated. This was due to the Toolbar rerendering each time the value changed which causes the focus to be lost
//
// https://github.com/mui/mui-x/issues/9580
function CustomToolbar(props: any) {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <GridToolbar sx={{ p: 0 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GridToolbarQuickFilter sx={{ ml: 2, mr: 1, p: 0 }} />
        <Box
          onClick={props.createPlayer}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            mr: 2,
          }}
        >
          <PersonAddIcon sx={{ color: props.theme.palette.primary.main }} />
        </Box>
      </Box>
    </GridToolbarContainer>
  );
}

export default function ScoutingDataGrid(props: ScoutingDataGridProps) {
  // HOOKS
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // CONTEXTS

  const { listInContext, setListInContext } = useListContext();
  const { setAlertOptions } = useAlertContext();

  // REFs
  const apiRef = useGridApiRef();

  // VARIABLES
  const listId = searchParams.get(SEARCH_PARAMS_LIST_ID);
  const loadList = listId !== undefined && listId !== null;
  const columnDefs = ScoutingDataGridColDefs({ colDefs: ["*"] });

  // STATES
  const [openCreatePlayerDialog, setOpenCreatePlayerDialog] =
    useState<boolean>(false);
  const [rowCountState, setRowCountState] = useState(0);
  // GRID STATES
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const [dataGridState, setDataGridState] =
    useState<GridInitialState>(initialGridState);
  const [density, setDensity] = useState<GridDensity>(DEFAULT_DENSITY);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>();
  const [pinnedColumns, setPinnedColumnFields] =
    useState<GridPinnedColumnFields>({
      left: [],
      right: [],
    });
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>();
  const [debouncedFilterModel] = useDebounce(filterModel, 500);
  const [debouncedSortModel] = useDebounce(sortModel, 500);

  // LOCAL STORAGE
  const [localStorageDataGridState, setLocalStorageDataGridState] =
    useLocalStorage(
      "player_scouting_database_state",
      JSON.stringify(dataGridState)
    );
  const gridState = JSON.parse(
    localStorageDataGridState ? localStorageDataGridState : "{}"
  );

  // QUERIES
  const listQueryResult = useList(
    "scouting",
    loadList,
    Number(listId),
    paginationModel?.page || DEFAULT_PAGE,
    paginationModel?.pageSize || DEFAULT_PAGE_SIZE,
    debouncedFilterModel,
    debouncedSortModel,
    setListInContext
  );
  const playersQueryResult = usePlayerDatabase(
    !loadList,
    VIEW_MODE_SCOUTING,
    paginationModel?.page || DEFAULT_PAGE,
    paginationModel?.pageSize || DEFAULT_PAGE_SIZE,
    debouncedFilterModel,
    debouncedSortModel
  );

  // VARIABLES
  const data = useCallback(() => {
    const d = loadList
      ? listQueryResult?.data?.players?.data || []
      : playersQueryResult?.data?.data || [];

    return d;
  }, [
    listQueryResult?.data?.players?.data,
    loadList,
    playersQueryResult?.data?.data,
  ]);

  // FUNCTIONS
  const saveDataGridState = useCallback(() => {
    // console.debug("saving datagrid state");
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();
      setLocalStorageDataGridState(JSON.stringify(currentState));
    }
    // eslint-disable-next-line
  }, [apiRef]);

  const onColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => setColumnVisibilityModel(model),
    [setColumnVisibilityModel]
  );

  const onDensityChange = useCallback(
    (density: GridDensity) => setDensity(density),
    [setDensity]
  );

  const onFilterModelChange = useCallback(
    (model: GridFilterModel) => {
      //   console.debug(details);
      setFilterModel(model);
    },
    [setFilterModel]
  );

  const onPaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setPaginationModel(model);
      queryClient.invalidateQueries([GET_PLAYERS, GET_LIST]);
    },
    [setPaginationModel, queryClient]
  );

  const onPinnedColumnFieldsChange = useCallback(
    (pinnedColumns: GridPinnedColumnFields) =>
      setPinnedColumnFields(pinnedColumns),
    [setPinnedColumnFields]
  );

  const onProcessRowUpdateError = useCallback(
    (error: Error) => {
      setAlertOptions({
        message: `failed to update playerInContext: ${error}`,
        severity: SEVERITY_ERROR,
      });
    },
    [setAlertOptions]
  );

  const onRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      // console.debug(selectionModel);
      setSelectionModel(selectionModel);
    },
    []
  );

  const onSortModelChange = useCallback(
    (model: GridSortModel) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const showPlayerProfileModal = useCallback(
    (params: GridRowParams) => {
      const p = params.row as Player;
      searchParams.set(SEARCH_PARAMS_PLAYER_ID, String(p.id));
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  // USEFFECTS
  useLayoutEffect(() => {
    // Merge defaults
    // console.debug(gridState);
    if (
      gridState?.pinnedColumns?.left &&
      gridState?.pinnedColumns?.left?.length === 0
    ) {
      const initialPinnedLeftCols = initialGridState?.pinnedColumns?.left;
      const existingPinnedLeftCols = gridState.pinnedColumns.left;
      gridState.pinnedColumns.left = initialPinnedLeftCols?.concat(
        existingPinnedLeftCols
      );
    }

    if (
      gridState?.columns?.columnVisibilityModel &&
      Object.keys(gridState?.columns?.columnVisibilityModel).length === 0
    ) {
      gridState.columns.columnVisibilityModel = initialColumnVisibilityModel;
    }

    setColumnVisibilityModel(gridState?.columns?.columnVisibilityModel);
    setDensity(gridState?.density);
    setFilterModel(gridState?.filter?.filterModel);
    setPaginationModel(gridState?.pagination?.paginationModel);
    setPinnedColumnFields(gridState?.pinnedColumns);
    setSortModel(gridState?.sorting?.sortModel);
    setDataGridState(gridState);

    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveDataGridState);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveDataGridState);
      window.removeEventListener("blur", saveDataGridState);
      saveDataGridState();
    };
    // eslint-disable-next-line
  }, [saveDataGridState]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  useEffect(() => {
    setRowCountState((prevRowCountState) => {
      if (listInContext && listQueryResult?.data) {
        return listQueryResult.data.players.total_data_count;
      } else if (playersQueryResult?.data) {
        // Covers the edge case where no actual data is returned
        if (playersQueryResult.data?.data.length === 0) {
          return 0;
        }

        return playersQueryResult.data.total_data_count;
      } else {
        return prevRowCountState;
      }
    });
  }, [
    listInContext,
    playersQueryResult.data,
    listQueryResult.data,
    setRowCountState,
  ]);

  return (
    <>
      <MutatePlayerDialog
        open={openCreatePlayerDialog}
        setOpen={setOpenCreatePlayerDialog}
      />
      <DataGridPremium
        apiRef={apiRef}
        aria-label={listInContext?.name || ""}
        autosizeOnMount={true}
        // checkboxSelection={props.checkboxSelection}
        columns={columnDefs}
        columnVisibilityModel={columnVisibilityModel}
        density={density as GridDensity}
        // disable reordering for now because it's causing an error with react-dnd as described here: https://stackoverflow.com/questions/72278695/mui-datagridpro-crashes-when-reordering-columns-by-dragdrop-when-already-using
        disableAggregation // aggregation appears to be broken for unknown reasons
        disableColumnReorder={true}
        disableRowSelectionOnClick={true}
        getRowId={getUniqueKeyPlayer}
        filterModel={filterModel}
        hideFooter={props.hideFooter || !data || rowCountState === 0}
        hideFooterSelectedRowCount
        ignoreDiacritics // https://mui.com/x/react-data-grid/filtering/quick-filter/#ignore-diacritics-accents
        initialState={dataGridState}
        isRowSelectable={(params: GridRowParams) => {
          //   console.debug(params);
          return params.row.id !== null && params.row.id !== undefined;
        }}
        keepNonExistentRowsSelected // https://mui.com/x/react-data-grid/selection/#usage-with-server-side-pagination
        key="playerInContext-scouting-database"
        loading={playersQueryResult.isFetching || listQueryResult.isFetching}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onDensityChange={onDensityChange}
        onPaginationModelChange={onPaginationModelChange}
        onFilterModelChange={onFilterModelChange}
        onPinnedColumnsChange={onPinnedColumnFieldsChange}
        onProcessRowUpdateError={onProcessRowUpdateError}
        onRowClick={showPlayerProfileModal}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onSortModelChange={onSortModelChange}
        pageSizeOptions={[DEFAULT_PAGE_SIZE, 100, 1000, 10000, 100000]}
        pagination
        paginationModel={paginationModel}
        paginationMode="server"
        pinnedColumns={pinnedColumns}
        rows={data()}
        rowCount={rowCountState}
        rowSelectionModel={selectionModel}
        slots={{
          noResultsOverlay: () => (
            <EmptyStateOverlay height="80vh" content="No players found" />
          ), // The no-results overlay is displayed when the Data Grid has no results after filtering.
          noRowsOverlay: () => (
            <EmptyStateOverlay height="80vh" content="No players found" />
          ), // The no-rows overlay is displayed when the Data Grid has no rows.
          toolbar: props.hideToolbar ? null : CustomToolbar,
        }}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
          toolbar: {
            theme: theme,
            createPlayer: () => setOpenCreatePlayerDialog(true),
          },
        }}
        sortingMode="server"
        sortModel={sortModel}
        sx={{
          border: 0,
          "& .MuiButton-text": {
            fontSize: isScreenSmall ? 13 : 16,
          },
          "& .MuiDataGrid-cell": {
            padding: 0,
          },
          //   "& .MuiDataGrid-footerContainer": {
          //     display: "flex",
          //     justifyContent: "left",
          //   },
        }}
      />
    </>
  );
}
