import { Box, Typography } from "@mui/material";

import { GetRatingLabel } from "../pages/scouting/utils";

export interface RatingProps {
  includeLabel?: boolean;
  isProjected?: boolean;
  value: number;
}

export default function Rating(props: RatingProps) {
  // const precision = props.team === ACADEMY_TEAM ? .1 : 1;
  // const max = props.team === ACADEMY_TEAM ? SCOUTING_ACADEMY_PLAYER_RATING_LABELS.length : SCOUTING_PLAYER_RATING_LABELS.length-1;
  // const rating = props.value ? props.value : -1;

  return (
    <Box sx={{ width: "50%" }} pb={1} margin="auto">
      {props.includeLabel && (
        <Typography variant="subtitle2">
          {props.isProjected ? "PROJECTED" : "RATING"}
        </Typography>
      )}

      <Typography component="legend" variant="caption">
        {GetRatingLabel(props.value)}
      </Typography>
    </Box>
  );
}
