import {
  Box,
  IconButton,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";

import ConfirmationDialog from "../components/dialogs/ConfirmationDialog";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { PlayerList } from "../api/types";
import RenameListDialog from "../components/dialogs/RenameListDialog";
import ShareMenu from "./ShareMenu";
import ShareTwoToneIcon from "@mui/icons-material/ShareTwoTone";
import { useDeleteListMutation } from "../api/mutations";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useShares } from "../api/queries";

export function ListActionMenu(props: { list: PlayerList }) {
  // CONTEXT
  const { email } = useOktaAuthContext();

  // STATE
  const [listToDelete, setListToDelete] = useState<PlayerList>(
    {} as PlayerList
  );
  const [deleteListModalOpen, setDeleteListModalOpen] = useState(false);
  const [editListDialogOpen, setEditListDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [shareMenuAnchorEl, setShareMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const shareMenuOpen = Boolean(shareMenuAnchorEl);

  // QUERIES
  const sharesQueryResult = useShares(props.list.id, "list");

  // MUTATIONS
  const deleteList = useDeleteListMutation();

  // FUNCTIONS

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleShareActionClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setShareMenuAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleShareMenuOnClose = (
    event: MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShareMenuAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <Box
      key={`${props.list.id}-${props.list.name}-list-action-menu-box`}
      sx={{ marginRight: 1.75 }}
    >
      <RenameListDialog
        open={editListDialogOpen}
        list={props.list}
        setOpen={setEditListDialogOpen}
      />

      <ConfirmationDialog
        key={`${props.list.id}-${props.list.name}-list-action-menuconfirmation-dialog`}
        open={deleteListModalOpen}
        title={`Are you sure you want to delete "${listToDelete.name}"?`}
        yesAction={() => {
          deleteList.mutate(listToDelete);
          setDeleteListModalOpen(false);
        }}
        noAction={() => {
          setDeleteListModalOpen(false);
        }}
      />

      <ShareMenu
        anchorEl={shareMenuAnchorEl}
        menuId={`${props.list.id}-share-menu`}
        menuListProps={{
          "aria-labelledby": "share-button",
        }}
        objectId={props.list.id}
        objectType="list"
        open={shareMenuOpen}
        onClose={(event: MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleShareMenuOnClose(event)
        }
        sharesQueryResult={sharesQueryResult}
      />

      <Menu
        key={`${props.list.id}-list-action-menu`}
        aria-labelledby="list-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <List key={`${props.list.id}-list-action-menu-list`}>
          {props.list.owner === email && (
            <MenuItem
              key={`${props.list.id}-list-action-menu-item-delete-list`}
              onClick={(event) => {
                setListToDelete(props.list);
                setDeleteListModalOpen(true);
                event.stopPropagation();
              }}
            >
              <ListItemIcon
                sx={{ mr: 1 }}
                key={`${props.list.id}-list-action-menu-item-list-delete`}
              >
                <DeleteTwoToneIcon
                  key={`${props.list.id}-list-action-menu-item-list-delete-icon`}
                  fontSize={"small"}
                  color="primary"
                />
              </ListItemIcon>

              <Typography
                variant="caption"
                key={`${props.list.id}-list-action-menu-item-list-delete-typography`}
              >
                DELETE
              </Typography>
            </MenuItem>
          )}

          {props.list.owner === email && (
            <MenuItem
              key={`${props.list.id}-list-action-menu-item-rename-list`}
              onClick={(event) => {
                setEditListDialogOpen(true);
                event.stopPropagation();
              }}
            >
              <ListItemIcon
                sx={{ mr: 1 }}
                key={`${props.list.id}-list-action-menu-menu-item-list-rename`}
              >
                <EditTwoToneIcon
                  key={`${props.list.id}-list-action-menu-menu-item-list-rename-icon`}
                  fontSize={"small"}
                  color="primary"
                />
              </ListItemIcon>

              <Typography
                key={`${props.list.id}-list-action-menu-menu-item-list-rename-typography`}
                variant="caption"
              >
                RENAME
              </Typography>
            </MenuItem>
          )}

          {props.list.owner === email && (
            <MenuItem
              key={`${props.list.id}-list-action-menu-item-share-list`}
              onClick={(event: any) => handleShareActionClick(event)}
            >
              <ListItemIcon
                sx={{ mr: 1 }}
                key={`${props.list.id}-list-action-menu-item-share-list`}
              >
                <ShareTwoToneIcon
                  key={`${props.list.id}-list-action-menu-item-share-list-icon`}
                  fontSize={"small"}
                  color="primary"
                />
              </ListItemIcon>

              <Typography
                key={`${props.list.id}-list-action-menu-item-share-list-typography`}
                variant="caption"
              >
                SHARE
              </Typography>
            </MenuItem>
          )}
        </List>
      </Menu>

      <IconButton
        key={`${props.list.id}-actions-button`}
        edge="end"
        aria-label="expand"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleMenuClick}
        sx={{
          p: 0,
        }}
      >
        <MoreVertIcon
          key={`${props.list.id}-list-action-menu-select-list-more-vert-icon`}
          fontSize={"small"}
          color="primary"
        />
      </IconButton>
    </Box>
  );
}
