import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardMedia,
  Grid2 as Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip as MuiTooltip,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { EMPTY_FIELD, SCOUTING_ROLE } from "../constants";
import { Note, Player } from "../api/types";
import { SEVERITY_INFO, useAlertContext } from "../contexts/AlertContext";
import { useCreateListMutation, useCreateNoteMutation } from "../api/mutations";
import {
  usePlayerAttributes,
  usePlayerNotes,
  usePlayerScoutingReports,
} from "../api/queries";

import { Accordion } from "./Accordion";
import CreateDialog from "./dialogs/CreateDialog";
import { EURCurrencyFormatter } from "../utils/currency";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayerAttributesView from "../pages/scouting/PlayerAttributesView";
import PlayerMetrics from "./PlayerMetrics";
import PlayerNotes from "./PlayerNotes";
import PlayerProfileActionsMenu from "./PlayerProfileActionsMenu";
import ScoutingReportsTable from "../pages/scouting/ScoutingReportsTable";
import SquareIcon from "@mui/icons-material/Square";
import SyncProblemOutlinedIcon from "@mui/icons-material/SyncProblemOutlined";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { calculateAge } from "../utils/dates";
import default_player from "../assets/default_player.jpeg";
import md5 from "md5";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useThemeContext } from "../contexts/CustomThemeContext";
import { useUserContext } from "../contexts/UserContext";
import { useWindowSizeContext } from "../contexts/WindowSizeContext";

interface PlayerProfileProps {
  player: Player;
  setPlayers?: Dispatch<SetStateAction<Player[]>>;
}

export default function PlayerProfile(props: PlayerProfileProps) {
  // CONTEXT
  const { setAlertOptions } = useAlertContext();
  const { email } = useOktaAuthContext();
  const { theme } = useThemeContext();
  const { height } = useWindowSizeContext();
  const { primaryRole } = useUserContext();

  // STATE
  // const [scoutingReportsDrawerOpen, setScoutingReportsDrawerOpen] = useState(false);
  const [createListDialogOpen, setCreateListDialogOpen] =
    useState<boolean>(false);
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const actionsMenuOpen = Boolean(actionsAnchorEl);

  const name = `${props.player?.first_name ? props.player?.first_name : ""} ${
    props.player?.last_name ? props.player?.last_name : ""
  }`;

  // FUNCTIONS
  const handleActionsMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
  };

  const playerNotesQuery = usePlayerNotes(props.player.id);
  const notes = playerNotesQuery.data;
  const playerAttributesQuery = usePlayerAttributes(props.player?.id);
  const playerAttributes = playerAttributesQuery.data;
  const playerScoutingReportsQuery = usePlayerScoutingReports(props.player?.id);
  const scoutingReports = playerScoutingReportsQuery.data;

  // MUTATIONS
  const createList = useCreateListMutation();
  const createNoteMutation = useCreateNoteMutation();

  // VARIABLES
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const uniqueKeyPrefix = md5(props.player?.name || "");
  //   const mlsEstimatedSalary = props.player?.mls_salary_est
  //     ? USDCurrencyFormatter.format(
  //         Math.ceil(props.player?.mls_salary_est / 10) * 10
  //       )
  //     : "";
  const valueTrend = props.player?.value_trend
    ? props.player?.value_trend
    : EMPTY_FIELD;

  const trendingIcon = () => {
    if (valueTrend === "increasing") {
      return <TrendingUpIcon color="success" />;
    } else if (valueTrend === "decreasing") {
      return <TrendingDownIcon color="error" />;
    } else if (valueTrend === EMPTY_FIELD) {
      return <></>;
    } else {
      return <TrendingFlatIcon color="primary" />;
    }
  };

  const textAlign = "center";
  const marketValue =
    EURCurrencyFormatter.format(
      Math.ceil(Number(props.player?.tm_value_num) / 10) * 10
    ) || EMPTY_FIELD;

  return (
    <Box key={`${uniqueKeyPrefix}-box`}>
      <CreateDialog
        key={`${uniqueKeyPrefix}-create-note-dialog`}
        open={createNoteModalOpen}
        setOpen={setCreateNoteModalOpen}
        rows={height / 70}
        title="Create Note"
        label="Note Content"
        yesAction={(newValue) => {
          createNoteMutation.mutate({
            content: newValue,
            player_id: props.player?.id,
          } as Note);
          setCreateNoteModalOpen(false);
        }}
      />

      <CreateDialog
        open={createListDialogOpen}
        setOpen={setCreateListDialogOpen}
        rows={1}
        title={"Create List"}
        label={"List Name"}
        yesAction={(value: string) => {
          createList.mutate({
            owner: email,
            name: value,
            players: [
              {
                id: props.player?.id,
                competition_id: props.player?.competition_id,
                season_id: props.player?.season_id,
              },
            ],
          });
          setCreateListDialogOpen(false);
        }}
      />

      <PlayerProfileActionsMenu
        anchorEl={actionsAnchorEl}
        handleActionMenuClose={handleActionsMenuClose}
        open={actionsMenuOpen}
        setCreateListDialogOpen={setCreateListDialogOpen}
        setCreateNoteModalOpen={setCreateNoteModalOpen}
      />

      <Grid
        key={`${uniqueKeyPrefix}-container`}
        container
        justifyContent={textAlign}
        spacing={2}
      >
        <Grid
          key={`${uniqueKeyPrefix}-card`}
          size={{ xs: 12, sm: 3 }}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Card
            raised={false}
            key={`${uniqueKeyPrefix}-card`}
            sx={{
              textAlign: "center",
              boxShadow: "0px 0px 0px 0px #fff;",
            }}
          >
            <CardMedia
              key={`${uniqueKeyPrefix}-card-img`}
              component="img"
              alt={name}
              image={props.player?.image ? props.player?.image : default_player}
              sx={{
                maxWidth: isScreenSmall ? "300px" : "300px",
                minHeight: isScreenSmall ? "200px" : "325px",
                maxHeight: isScreenSmall ? "200px" : "340px",
              }}
            />
          </Card>
        </Grid>

        <Grid
          key={`${uniqueKeyPrefix}-player-data-container`}
          size={{ xs: 12, sm: 9 }}
          sx={{ mt: 2 }}
        >
          <Grid
            container
            key={`${uniqueKeyPrefix}-player-name-container`}
            alignItems={textAlign}
            display="flex"
            justifyContent={"space-between"}
            pr={isScreenSmall ? 0 : 2}
            sx={{
              mt: isScreenSmall ? 1 : 0,
              ml: isScreenSmall ? 0 : 1,
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <MuiTooltip
                key={`${uniqueKeyPrefix}-tm-link-tooltip`}
                title={"Go to Transfermarkt Profile"}
              >
                <Link
                  key={`${uniqueKeyPrefix}-tm-data-container-player-name-typography`}
                  alignContent={textAlign}
                  noWrap={true}
                  onClick={() => {
                    if (props.player?.tm_link) {
                      window.open(
                        props.player?.tm_link,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    } else {
                      setAlertOptions({
                        message: `${
                          props.player?.player_name
                            ? props.player?.player_name
                            : "this player"
                        } doesn't have a transfermakrt profile`,
                        severity: SEVERITY_INFO,
                      });
                    }
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                  variant={"h4"}
                >
                  {name}
                </Link>
              </MuiTooltip>

              <MuiTooltip
                key={`${uniqueKeyPrefix}-value-trend-tooltip`}
                title={`Value Trend: ${valueTrend}`}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Link href={props.player?.tm_link} target="_blank">
                  {trendingIcon()}
                </Link>
              </MuiTooltip>

              {props.player?.mls_eligible && (
                <MuiTooltip
                  key={`${uniqueKeyPrefix}-mls-eligible-tooltip`}
                  title={"This player is eligible to play in the MLS!"}
                >
                  <HowToRegOutlinedIcon color="secondary" />
                </MuiTooltip>
              )}

              {props.player?.recently_changed && (
                <MuiTooltip
                  key={`${uniqueKeyPrefix}-recently-changed-tooltip`}
                  title={"Team, Contract or Market Value recently changed!"}
                >
                  <SyncProblemOutlinedIcon color="secondary" />
                </MuiTooltip>
              )}
            </Stack>

            <Box>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
              >
                {/* <MuiTooltip
                  key={`${uniqueKeyPrefix}-estimated-mls-salary-tooltip`}
                  title={`Estimated Salary in MLS: ${mlsEstimatedSalary}`}
                >
                  <Typography
                    key={`${uniqueKeyPrefix}-mls-salary-typography`}
                    sx={{ typography: { xs: "h5" } }}
                  >
                    {mlsEstimatedSalary ? mlsEstimatedSalary : ""}
                  </Typography>
                </MuiTooltip> */}

                {props.player?.id && (
                  <MuiTooltip
                    key={`${uniqueKeyPrefix}-player-actions-tooltip`}
                    title="Actions"
                  >
                    <IconButton
                      key={`${props.player?.id}-actions-button`}
                      edge="end"
                      aria-label="expand"
                      aria-controls={actionsMenuOpen ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={actionsMenuOpen ? "true" : undefined}
                      onClick={handleActionsMenuButtonClick}
                    >
                      <MoreVertIcon
                        key={`${props.player?.id}-player-actions-menu-vert-icon`}
                        fontSize={"small"}
                        color={"primary"}
                      />
                    </IconButton>
                  </MuiTooltip>
                )}
              </Stack>
            </Box>
          </Grid>

          <Grid
            container
            key={`${uniqueKeyPrefix}-player-data`}
            ml={isScreenSmall ? 1 : 0}
            justifyContent="left"
            alignItems="left"
            display="flex"
          >
            {(props.player?.tm_id && (
              <>
                <Grid
                  key={`${uniqueKeyPrefix}-left-data`}
                  size={{ xs: 6, md: 3 }}
                >
                  <List
                    key={`${uniqueKeyPrefix}-list-1-data`}
                    disablePadding={isScreenSmall}
                    dense
                    sx={{
                      key: `${uniqueKeyPrefix}-list`,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-team-text`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-team-tooltip`}
                        title={`${props.player?.team || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-team-text`}
                          primary="Team"
                          secondary={<>{props.player?.team || EMPTY_FIELD}</>}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-age`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-age-tooltip`}
                        title={`${props.player?.age || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-age-text`}
                          primary="Age"
                          secondary={
                            props.player?.age
                              ? props.player?.age
                              : props.player?.birthdate
                              ? calculateAge(props.player?.birthdate)
                              : EMPTY_FIELD
                          }
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-nationality`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-nationality-tooltip`}
                        title={`${props.player?.nationality || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-nationality-text`}
                          primary="Nationality"
                          secondary={props.player?.nationality || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>
                  </List>
                </Grid>

                <Grid
                  key={`${uniqueKeyPrefix}-left-middle-data`}
                  size={{ xs: 6, md: 3 }}
                >
                  <List
                    key={`${uniqueKeyPrefix}-list-2-data`}
                    disablePadding={isScreenSmall}
                    dense
                    sx={{
                      key: `${uniqueKeyPrefix}-list-2`,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-country`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-country-tooltip`}
                        title={`${props.player?.country || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-country-text`}
                          primary="League Country"
                          secondary={props.player?.country || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-league`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-league-tooltip`}
                        title={`${props.player?.league || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-league-text`}
                          primary="League"
                          secondary={props.player?.league || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-position`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-position-tooltip`}
                        title={`${props.player?.position || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-position-text`}
                          primary="Position"
                          secondary={props.player?.position || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>
                  </List>
                </Grid>

                <Grid
                  key={`${uniqueKeyPrefix}-right-middle-data`}
                  size={{ xs: 6, md: 3 }}
                >
                  <List
                    key={`${uniqueKeyPrefix}-list-3-data`}
                    disablePadding={isScreenSmall}
                    dense
                    sx={{
                      key: `${uniqueKeyPrefix}-list-3`,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-birthdate`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-birtdate-tooltip`}
                        title={`${props.player?.birthdate || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-birthdate-text`}
                          primary="Date of Birth"
                          secondary={<>{props.player?.birthdate || "N/A"}</>}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      dense
                      key={`${uniqueKeyPrefix}-foot`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-foot-tooltip`}
                        title={`${props.player?.foot || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-foot-text`}
                          primary="Foot"
                          secondary={props.player?.foot || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      dense
                      key={`${uniqueKeyPrefix}-height`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-height-tooltip`}
                        title={`${props.player?.height || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-height-text`}
                          primary="Height"
                          secondary={props.player?.height || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>
                  </List>
                </Grid>

                <Grid
                  key={`${uniqueKeyPrefix}-right-data`}
                  size={{ xs: 6, md: 3 }}
                >
                  <List
                    key={`${uniqueKeyPrefix}-list-4-data`}
                    disablePadding={isScreenSmall}
                    dense
                    sx={{
                      key: `${uniqueKeyPrefix}-list-4`,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-market-value`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-market-value-tooltip`}
                        title={marketValue}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-market-value-text`}
                          primary="Market Value"
                          secondary={marketValue}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-agent`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-agent-tooltip`}
                        title={`${props.player?.agent || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-agent-text`}
                          primary="Agent"
                          secondary={props.player?.agent || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-contract`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-contract-tooltip`}
                        title={`${props.player?.contract || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-contract-text`}
                          primary="Contract"
                          secondary={
                            <>{props.player?.contract || EMPTY_FIELD}</>
                          }
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>
                  </List>
                </Grid>
              </>
            )) || (
              <>
                <Grid
                  key={`${uniqueKeyPrefix}-left-data`}
                  size={{ xs: 6, md: 3 }}
                >
                  <List
                    key={`${uniqueKeyPrefix}-list-1-data`}
                    disablePadding={isScreenSmall}
                    dense
                    sx={{
                      key: `${uniqueKeyPrefix}-list`,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-team-text`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-team-tooltip`}
                        title={`${props.player?.team || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-team-text`}
                          primary="Team"
                          secondary={<>{props.player?.team || EMPTY_FIELD}</>}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-league`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-league-tooltip`}
                        title={`${props.player?.league || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-league-text`}
                          primary="League"
                          secondary={props.player?.league || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>
                  </List>
                </Grid>
                <Grid
                  key={`${uniqueKeyPrefix}-left-data`}
                  size={{ xs: 6, md: 3 }}
                >
                  <List
                    key={`${uniqueKeyPrefix}-list-1-data`}
                    disablePadding={isScreenSmall}
                    dense
                    sx={{
                      key: `${uniqueKeyPrefix}-list`,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-birthdate-text`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-birthdate-tooltip`}
                        title={`${props.player?.birthdate || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-birthdate-text`}
                          primary="Date of Birth"
                          secondary={
                            <>{props.player?.birthdate || EMPTY_FIELD}</>
                          }
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-age`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-age-tooltip`}
                        title={`${props.player?.league || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-age-text`}
                          primary="Age"
                          secondary={
                            props.player?.age
                              ? props.player?.age
                              : props.player?.birthdate
                              ? calculateAge(props.player?.birthdate)
                              : EMPTY_FIELD
                          }
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>
                  </List>
                </Grid>
                <Grid
                  key={`${uniqueKeyPrefix}-left-data`}
                  size={{ xs: 6, md: 3 }}
                >
                  <List
                    key={`${uniqueKeyPrefix}-list-1-data`}
                    disablePadding={isScreenSmall}
                    dense
                    sx={{
                      key: `${uniqueKeyPrefix}-list`,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-position-text`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-position-tooltip`}
                        title={`${props.player?.position || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-position-text`}
                          primary="Position"
                          secondary={
                            <>{props.player?.position || EMPTY_FIELD}</>
                          }
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>

                    <ListItem
                      disablePadding={isScreenSmall}
                      key={`${uniqueKeyPrefix}-nationality`}
                    >
                      <MuiTooltip
                        key={`${uniqueKeyPrefix}-age-tooltip`}
                        title={`${props.player?.nationality || EMPTY_FIELD}`}
                      >
                        <ListItemText
                          key={`${uniqueKeyPrefix}-nationality-text`}
                          primary="Nationality"
                          secondary={props.player?.nationality || EMPTY_FIELD}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                      </MuiTooltip>
                    </ListItem>
                  </List>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {playerAttributesQuery.isFetched &&
          playerScoutingReportsQuery.isFetched &&
          scoutingReports &&
          scoutingReports.length > 0 && (
            <Grid size={{ xs: 12 }} display="flex" justifyContent="center">
              <Accordion
                sx={{ width: "100%", borderColor: theme.palette.info.main }}
                defaultExpanded={primaryRole === SCOUTING_ROLE}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <SquareIcon
                    sx={{ marginRight: 1, color: theme.palette.primary.main }}
                  />
                  <Typography variant="subtitle2">Scouting Reports</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PlayerAttributesView playerAttributes={playerAttributes} />
                  <ScoutingReportsTable scoutingReports={scoutingReports} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

        {playerNotesQuery.isFetched && notes && notes.length > 0 && (
          <Grid size={{ xs: 12 }} display="flex" justifyContent="center">
            <Accordion
              sx={{ width: "100%", borderColor: theme.palette.info.main }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <SquareIcon
                  sx={{ marginRight: 1, color: theme.palette.info.main }}
                />
                <Typography variant="subtitle2">Notes</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <PlayerNotes dialog={false} notes={notes} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        <Grid key={`${uniqueKeyPrefix}-player-metrics`} size={{ xs: 12 }}>
          <PlayerMetrics />
        </Grid>
      </Grid>
    </Box>
  );
}
