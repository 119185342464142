import {
  Container,
  Stack,
  SwipeableDrawer,
  Table,
  TableBody,
  TableContainer,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { EnhancedTableHead, EnhancedTableHeadProps } from "./EnhancedTableHead";

import Box from "@mui/material/Box";
import CloseButton from "../buttons/CloseButton";
import EnhancedTableLegend from "./EnhancedTableLegend";
import EnhancedTableRow from "./EnhancedTableRow";
import { TableCellsFunc } from "./EnhancedTableCell";
import { getComparator } from "./utils";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

export interface EnhancedTableProps<T> extends EnhancedTableHeadProps<T> {
  data: T[];
  caption?: string;
  drawerContent?: JSX.Element;
  height?: string;
  selectedRow?: T;
  setSelectedRow?: Dispatch<SetStateAction<T | undefined>>;
  selectors?: JSX.Element;
  selectedMetric?: string;
  selectedMetricName?: string;
  title?: string;
  tableCells: TableCellsFunc<T>;
}

export default function EnhancedTable<T>(props: EnhancedTableProps<T>) {
  // CONTEXT
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { height } = useWindowSizeContext();

  // STATE
  const [drawerOpen, setDrawerOpen] = useState(false);

  // FUNCTIONS
  const compareFn = getComparator(props.order, props.sortKey);

  const toggleDrawer = useCallback(
    async (open: boolean) => {
      setDrawerOpen(open);
    },
    [setDrawerOpen]
  );

  const onRowClick =
    (datum: T) => (event: React.KeyboardEvent | React.MouseEvent) => {
      props.setSelectedRow && props.setSelectedRow(datum);
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      toggleDrawer(!drawerOpen);
    };
  const scrollRef = useRef<HTMLTableRowElement>(null);

  // EFFECTS
  useEffect(() => {
    if (props.selectedRow) {
      scrollRef.current?.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRow, scrollRef.current]);

  return (
    <Box p={1}>
      {props.drawerContent && (
        <SwipeableDrawer
          anchor={"bottom"}
          open={drawerOpen}
          onOpen={() => {}}
          onClose={() => toggleDrawer(false)}
          sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        >
          <Container
            sx={{
              padding: 0,
              display: "flex",
              height: {
                xs: "100%",
                sm: "80%",
                md: "55%",
              },
              minHeight: {
                xs: `${height}px`,
                sm: "405px",
                md: "475px",
              },
            }}
          >
            <CloseButton
              position="absolute"
              top={5}
              left={5}
              onClick={() => toggleDrawer(false)}
            />
            {props.drawerContent}
          </Container>
        </SwipeableDrawer>
      )}

      <TableContainer
        sx={{ borderRadius: 0, maxHeight: "85vh", cursor: "pointer" }}
      >
        {(props.title || props.caption) && (
          <Toolbar sx={{ width: "90%" }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={2}
              direction="column"
              sx={{ width: "100%" }}
            >
              {!isScreenSmall && props.title && (
                <Typography variant="h5">
                  {props.title.toUpperCase()}
                </Typography>
              )}

              {!isScreenSmall && props.caption && (
                <Typography variant="caption">{props.caption}</Typography>
              )}
            </Stack>
          </Toolbar>
        )}

        <Table size="small" stickyHeader sx={{ borderRadius: 0 }}>
          <EnhancedTableHead
            headCells={props.headCells}
            order={props.order}
            setOrder={props.setOrder}
            sortKey={props.sortKey}
            setSortKey={props.setSortKey}
          />

          <TableBody>
            {props.data.sort(compareFn).map((datum: T, i) => {
              const selected = props.selectedRow === datum;
              return (
                <EnhancedTableRow
                  key={i}
                  theme={theme}
                  onClick={onRowClick(datum)}
                  hover
                  selected={selected}
                  ref={selected ? scrollRef : null}
                >
                  {props.tableCells(datum)}
                </EnhancedTableRow>
              );
            })}
          </TableBody>
        </Table>

        <EnhancedTableLegend headCells={props.headCells} />
      </TableContainer>
    </Box>
  );
}
