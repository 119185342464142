import { SvgIcon } from "@mui/material";
import { ReactComponent as concacafIcon } from "../assets/logos/concacaf.svg";
import { ReactComponent as confederationIcon } from "../assets/icons/confederation.svg";
import { ReactComponent as fieldIcon } from "../assets/icons/field.svg";
import { ReactComponent as globeIcon } from "../assets/icons/globe.svg";
import { ReactComponent as midwestIcon } from "../assets/icons/midwest.svg";
import { ReactComponent as norowsIcon } from "../assets/icons/norows.svg";
import { ReactComponent as polarIcon } from "../assets/icons/polar.svg";
import { ReactComponent as radarIcon } from "../assets/icons/radar.svg";

export const ConcacafIcon = () => { return <SvgIcon component={concacafIcon} inheritViewBox style={{ width: "3rem", height: "3rem" }} /> };
export const ConfederationIcon = () => { return <SvgIcon component={confederationIcon} inheritViewBox style={{ width: "3rem", height: "3rem" }} /> };
export const FieldIcon = () => { return <SvgIcon component={fieldIcon} inheritViewBox /> };
export const GlobeIcon = () => { return <SvgIcon component={globeIcon} inheritViewBox style={{ width: "2rem", height: "2rem" }} /> };
export const MidwestIcon = () => { return <SvgIcon component={midwestIcon} inheritViewBox style={{ width: "3rem", height: "3rem" }} /> };
export const NoRowsFoundIcon = () => { return <SvgIcon component={norowsIcon} inheritViewBox style={{ opacity: .26, width: "6rem", height: "6rem" }} /> };
export const PolarIcon = () => { return <SvgIcon stroke="currentColor" component={polarIcon} inheritViewBox /> };
export const RadarIcon = () => { return <SvgIcon stroke="currentColor" component={radarIcon} inheritViewBox /> };
