import { Folder, PlayerList } from "../api/types";
import { IconButton, Tooltip } from "@mui/material";

import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import ListLabel from "./ListLabel";
import { PLAYERS_ROUTE } from "../routes";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import { TreeItem } from "./TreeItem";
import { useListContext } from "../contexts/ListContext";
import { useNavigate } from "react-router-dom";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";

interface NavListProps {
  folder: Folder;
  list: PlayerList;
  folderIndex: number;
  index: number;
}

export default function NavList(props: NavListProps) {
  const navigate = useNavigate();
  const { listInContext, setListInContext } = useListContext();
  const { email } = useOktaAuthContext();

  const selectedList = props.list.id === listInContext?.id;
  const color = selectedList ? "secondary" : "primary";

  const ListIcon = () => {
    return (
      <Tooltip
        title={
          selectedList
            ? `Deselect ${props.list.name}`
            : `Select ${props.list.name}`
        }
      >
        <IconButton
          key={`${props.folderIndex}-${props.index}-${props.folder.id}-${props.list.id}-tree-item-select-list`}
          size="small"
          color={color}
          onClick={(event: { stopPropagation: () => void }) => {
            if (!selectedList) {
              setListInContext(props.list);
            } else {
              setListInContext(undefined);
            }
            event.stopPropagation();
          }}
          sx={{
            marginLeft: 1,
          }}
        >
          {selectedList && <CancelTwoToneIcon />}
          {!selectedList && <RadioButtonUncheckedRoundedIcon />}
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <TreeItem
      draggable={props.list.owner === email}
      itemId={`${props.folderIndex}-${props.index}-${props.folder.id}-${props.folder.name}-tree-item-label`}
      label={<ListLabel folder={props.folder} list={props.list} />}
      slots={{
        icon: ListIcon,
      }}
      onClick={() => {
        if (props.list === listInContext) {
          setListInContext(undefined);
          navigate(PLAYERS_ROUTE);
        } else {
          setListInContext(props.list);
          navigate(`${PLAYERS_ROUTE}?list_id=${props.list.id}`);
        }
      }}
    />
  );
}
