import { Dialog, DialogContent, useMediaQuery } from "@mui/material";

import CloseButton from "../buttons/CloseButton";
import PlayerProfile from "../PlayerProfile";
import { SEARCH_PARAMS_PLAYER_ID } from "../../constants";
import { usePlayerContext } from "../../contexts/PlayerContext";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function PlayerProfileDialog() {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const { playerInContext, setPlayerInContext } = usePlayerContext();

  // console.debug(playerInContext)

  const handleClose = () => {
    searchParams.delete(SEARCH_PARAMS_PLAYER_ID);
    setSearchParams(searchParams);
    setPlayerInContext(undefined);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={!!playerInContext || !!searchParams.get(SEARCH_PARAMS_PLAYER_ID)}
      PaperProps={{
        style: {
          borderRadius: 0,
          maxWidth: "100%",
        },
      }}
      fullScreen={true}
      sx={{
        zIndex: theme.zIndex.appBar + 1,
      }}
    >
      <CloseButton
        position="absolute"
        top={5}
        left={5}
        onClick={() => handleClose()}
      />

      <DialogContent
        sx={{
          paddingRight: isScreenSmall ? 1 : 20,
          paddingLeft: isScreenSmall ? 1 : 20,
        }}
      >
        {playerInContext && <PlayerProfile player={playerInContext} />}
      </DialogContent>
    </Dialog>
  );
}
