import { Box, SwipeableDrawer, useMediaQuery } from "@mui/material";

import { APPBAR_HEIGHT } from "../app_bar/Toolbar";
import { Folder } from "../api/types";
import NavMenu from "./NavMenu";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useThemeContext } from "../contexts/CustomThemeContext";

type NavDrawerProps = {
    drawerOpen: boolean;
    folders: Folder[];
    setDrawerOpen: (drawerOpen: boolean) => void;
};

export default function NavDrawer(props: NavDrawerProps) {
    // CONTEXT
    const { email } = useOktaAuthContext();
    const { theme } = useThemeContext();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const toggleDrawer =
        (drawerOpen: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === "keydown" &&
                    ((event as React.KeyboardEvent).key === "Tab" ||
                        (event as React.KeyboardEvent).key === "Shift")
                ) {
                    return;
                }

                if (event?.currentTarget?.textContent === "") {
                    props.setDrawerOpen(drawerOpen);
                }
            };

    // Return nothing so as to prevent bad API calls if the user isn't loaded from Okta
    if (!email) {
        return <></>;
    }

    return (
        <SwipeableDrawer
            open={props.drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={{
                zIndex: theme.zIndex.appBar - 1
            }}
        >
            <Box
                sx={{
                    marginTop: APPBAR_HEIGHT,
                    height: "100%",
                    width: isScreenSmall ? 350 : 400,
                    backgroundColor: "#ffffff",
                }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <NavMenu folders={props.folders} />
            </Box>
        </SwipeableDrawer>
    );
}
