import "./index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import App from "./App";
import CacheBuster from "react-cache-buster";
import { LicenseInfo } from "@mui/x-license";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import { createRoot } from "react-dom/client";
import { datadogRum } from "@datadog/browser-rum";
import en from "javascript-time-ago/locale/en";
import { init as initFullStory } from "@fullstory/browser";
import packageJson from "../package.json";
import reportWebVitals from "./reportWebVitals";

console.debug(`Version: ${packageJson.version}`);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY as string);
TimeAgo.addDefaultLocale(en);

// console.debug(process.env.NODE_ENV);
const isProduction = process.env.NODE_ENV === "production";

if (isProduction) {
  initFullStory({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID || "" });
}

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID || "",
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
  service: "the-pond",
  env: process.env.NODE_ENV,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us5.datadoghq.com",
  version: packageJson.version,
  trackUserInteractions: true,
  trackResources: true,
  defaultPrivacyLevel: "mask-user-input",
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  startSessionReplayRecordingManually: true,
  trackLongTasks: true,
  enablePrivacyForActionName: true,
});

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={true}
      isVerboseMode={true}
      metaFileDirectory={`/meta.json?t=${Date.now()}#`}
    >
      <Router>
        <App />
      </Router>
    </CacheBuster>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
