import { createContext, useContext, useEffect, useState } from "react";

import { useQueryClient } from "react-query";

const WEB_SOCKET_URL = process.env.REACT_APP_API_URL_WS || "";

const defaultState = {
  ws: undefined,
};

interface WebSocketContextInterface {
  ws: WebSocket | undefined;
}

export const WebSocketContext =
  createContext<WebSocketContextInterface>(defaultState);

let websocket = new WebSocket(WEB_SOCKET_URL + "/websockets/");

export const WebSocketProvider = ({children}: {children: React.ReactNode}) => {
  const queryClient = useQueryClient();
  const [ws, setWs] = useState<WebSocket>();

  useEffect(() => {
    
    setWs(websocket);

    websocket.onopen = () => {
      console.log("Connected to websocket");
    };

    websocket.onmessage = (event) => {
      const data = event.data;
      // console.debug(data);
      queryClient.invalidateQueries(data);
    };

    websocket.onclose = (event) => {
      websocket = new WebSocket(WEB_SOCKET_URL + "/websockets/");
      setWs(websocket);
    };

    websocket.onerror = (error) => {
      console.error("WebSocket error. Closing WebSocket");
      websocket.close();
    };
  }, [queryClient]);

  const value = { ws };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => useContext(WebSocketContext);
