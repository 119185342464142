import { CHICAGO_FIRE, CHICAGO_FIRE_2, SECOND_TEAM } from "../constants";
import { Dispatch, SetStateAction } from "react";
import {
  GET_AUTH,
  GET_CONSTANT_COUNTRIES,
  GET_CONSTANT_LEAGUES,
  GET_CONSTANT_SCHOOLS,
  GET_DEPTH_CHART,
  GET_DEPTH_CHARTS,
  GET_FOLDERS,
  GET_KIT_MANAGER_ACTIONS,
  GET_KIT_MANAGER_ACTION_LOGS,
  GET_KIT_MANAGER_PLAYERS,
  GET_LIST,
  GET_LISTS,
  GET_NOTES,
  GET_NOTIFICATIONS,
  GET_OPERATIONS_DATA,
  GET_PLAYER,
  GET_PLAYERS,
  GET_PLAYER_ATTRIBUTES,
  GET_PLAYER_DATABASE_META,
  GET_PLAYER_KPIS,
  GET_PLAYER_NOTES,
  GET_PLAYER_SCOUTING_REPORTS,
  GET_PLAYER_WEEKLY_STATS,
  GET_SCHEDULING_GAMES,
  GET_SCHEDULING_WEEKS,
  GET_SCOUTED_PLAYERS,
  GET_SCOUTING_REPORTS,
  GET_SHARES,
  GET_TABLE_METRICS,
  GET_TEAM_STATS,
  GET_TOTW_WEEKS,
  GET_USERS,
  PLAYER_SEARCH,
  SCOUTED_PLAYER_SEARCH,
} from "./keys";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-premium";
import { Player, PlayerList, SchedulingGame } from "./types";
import {
  formatDateToLocal,
  formatDateToLocalWithoutTime,
  getUTCDate,
} from "../utils/dates";

import { useAPIContext } from "../contexts/APIContext";
import { useClubContext } from "../contexts/ClubContext";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useQuery } from "react-query";
import { useTeamContext } from "../contexts/TeamContext";

export function useAuth() {
  const { apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();

  return useQuery(
    [GET_AUTH],
    async () => {
      return await apiClient.getAuth(email);
    },
    {
      enabled: !!email,
    }
  );
}

export function useDepthChart(depthChartId: number) {
  const { apiClient } = useAPIContext();

  return useQuery([GET_DEPTH_CHART], async () => {
    return await apiClient.getDepthChart(depthChartId);
  });
}

export function useDepthCharts() {
  const { apiClient } = useAPIContext();

  return useQuery([GET_DEPTH_CHARTS], async () => {
    return await apiClient.getDepthCharts();
  });
}

export function useFolders(owner: string | undefined) {
  const { apiClient } = useAPIContext();

  return useQuery([GET_FOLDERS], async () => {
    if (owner) {
      return await apiClient.getFolders(owner);
    } else {
      return [];
    }
  });
}

export function useLeagueTable() {
  const { apiClient } = useAPIContext();
  const { club } = useClubContext();
  const { team } = useTeamContext();
  const queryKey = [GET_TABLE_METRICS, { club: club, team: team }];

  return useQuery(queryKey, async () => {
    return await apiClient.getTeamTable(
      club === CHICAGO_FIRE && team === SECOND_TEAM ? CHICAGO_FIRE_2 : club
    );
  });
}

export function useList(
  mode: string,
  enabled: boolean,
  listId: number,
  pageNumber: number,
  pageSize: number,
  filters: GridFilterModel | undefined,
  sortModel: GridSortModel | undefined,
  setListInContext?: Dispatch<SetStateAction<PlayerList | undefined>>
) {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();

  return useQuery(
    [
      GET_LIST,
      {
        mode: mode,
        email: email,
        listId: listId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        filters: filters,
        sortModel: sortModel,
      },
    ],
    async () => {
      const list = await apiClient.getList(
        mode,
        listId,
        email,
        pageNumber,
        pageSize,
        filters,
        sortModel
      );
      list && setListInContext && setListInContext(list);
      return list;
    },
    {
      enabled: enabled && filters !== undefined && sortModel !== undefined,
    }
  );
}

export function useLists() {
  const { apiClient, authenticated } = useAPIContext();
  const { email } = useOktaAuthContext();

  return useQuery(
    [GET_LISTS, { email: email }],
    async () => {
      return await apiClient.getLists(email);
    },
    {
      enabled: email !== null && authenticated,
    }
  );
}

export function useNotes() {
  const { apiClient } = useAPIContext();

  return useQuery([GET_NOTES], async () => {
    return await apiClient.getNotes();
  });
}

export function usePlayer(enabled: boolean, playerId: number) {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_PLAYER, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayer(playerId);
      }
    },
    {
      enabled: enabled && authenticated,
    }
  );
}

export function usePlayerAttributes(playerId: number | undefined) {
  const { apiClient } = useAPIContext();

  return useQuery([GET_PLAYER_ATTRIBUTES, { playerId: playerId }], async () => {
    if (playerId) {
      return await apiClient.getPlayerAttributes(playerId);
    }
  });
}

export function usePlayerDatabaseMeta() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_PLAYER_DATABASE_META],
    async () => {
      return await apiClient.getPlayerDatabaseMeta();
    },
    { enabled: authenticated }
  );
}

export function usePlayerKPIs(enabled: boolean, playerId: number) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [[GET_PLAYER_KPIS, playerId], { playerId: playerId }],
    async () => {
      if (enabled) {
        return await apiClient.getPlayerKPIs(playerId);
      }
    },
    {
      enabled: enabled,
    }
  );
}

export function usePlayerScoutingReports(playerId: number | undefined) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_SCOUTING_REPORTS, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayerScoutingReports(playerId);
      }
    }
  );
}

export function usePlayerNotes(playerId: number | undefined) {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_PLAYER_NOTES, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayerNotes(playerId);
      }
    },
    {
      enabled: !!playerId && authenticated,
    }
  );
}

export function usePlayerDatabase(
  enabled: boolean,
  mode: string,
  pageNumber: number,
  pageSize: number,
  filters: GridFilterModel | undefined,
  sortModel: GridSortModel | undefined
) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [
      GET_PLAYERS,
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filters: filters,
        sortModel: sortModel,
      },
    ],
    async () => {
      return await apiClient.getPlayers(
        mode,
        pageNumber,
        pageSize,
        filters,
        sortModel
      );
    },
    {
      enabled: enabled && filters !== undefined && sortModel !== undefined,
    }
  );
}

export function usePlayerWeeklyStats(player: Player, metric: string) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_WEEKLY_STATS, { playerId: player.id, metric: metric }],
    async () => {
      return await apiClient.getPlayersWeeklyStats(player, metric);
    }
  );
}

export function useNotifications() {
  const { apiClient, authenticated } = useAPIContext();
  const { email } = useOktaAuthContext();

  return useQuery(
    [GET_NOTIFICATIONS],
    async () => {
      return await apiClient.getNotifications(email);
    },
    {
      enabled: !!email && authenticated,
      refetchOnWindowFocus: true,
    }
  );
}

export function useScoutedPlayers() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_SCOUTED_PLAYERS],
    async () => {
      return await apiClient.getScoutedPlayers();
    },
    {
      enabled: authenticated,
    }
  );
}

export function useScoutingReports() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_SCOUTING_REPORTS],
    async () => {
      return await apiClient.getScoutingReports();
    },
    {
      enabled: authenticated,
    }
  );
}

export function useShares(objectId: number | undefined, objectType: string) {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();

  return useQuery(
    [GET_SHARES, { email, objectId, objectType }],
    async () => {
      if (email && objectId) {
        return await apiClient.getShares(email, objectId, objectType);
      } else {
        return [];
      }
    },
    {
      staleTime: 0,
    }
  );
}

export function usePlayerSearch(
  submittedParams: { query: string; by: string },
  pageSize: number,
  pageNumber: number
) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [PLAYER_SEARCH, { submittedParams, pageNumber, pageSize }],
    async () => {
      return await apiClient.searchPlayers(
        submittedParams.query,
        submittedParams.by,
        pageSize,
        pageNumber
      );
    },
    {
      enabled: !!submittedParams.query && !!submittedParams.by,
    }
  );
}

export function useScoutedPlayerSearch(
  submittedParams: { query: string; by: string },
  pageSize: number,
  pageNumber: number
) {
  const { apiClient } = useAPIContext();

  return useQuery(
    [SCOUTED_PLAYER_SEARCH, { submittedParams, pageNumber, pageSize }],
    async () => {
      return await apiClient.searchScoutedPlayers(
        submittedParams.query,
        submittedParams.by,
        pageSize,
        pageNumber
      );
    },
    {
      enabled: !!submittedParams.query && !!submittedParams.by,
    }
  );
}

export function useTeamSearch(
  submittedParams: { query: string | null; by: string },
  pageSize: number,
  pageNumber: number
) {
  // const {apiClient} = useAPIContext();
  // return useQuery(
  //   [TEAM_SEARCH, { submittedParams, pageNumber, pageSize }],
  //   async () => {
  //     return await apiClient.searchTeams(
  //       submittedParams.query,
  //       submittedParams.by,
  //       pageSize,
  //       pageNumber
  //     )
  //   },
  //   {
  //     enabled: !!submittedParams.query && !!submittedParams.by,
  //     errorBoundaryHttpCode: 500,
  //   })
  // )
}

export function useTeamMetrics(
  startDate: Date,
  endDate: Date,
  teamName: string
) {
  const { apiClient } = useAPIContext();

  const startDateCacheKey = formatDateToLocalWithoutTime(startDate);
  const endDateCacheKey = formatDateToLocalWithoutTime(endDate);

  return useQuery(
    [GET_TEAM_STATS, { startDateCacheKey, endDateCacheKey, teamName }],
    async () => {
      return await apiClient.getTeamStats(startDate, endDate, teamName);
    }
  );
}

export function useConstantUsers() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_USERS],
    async () => {
      return await apiClient.getUsers();
    },
    {
      enabled: authenticated,
      staleTime: 0,
    }
  );
}

export function useTotwWeeks() {
  const { apiClient } = useAPIContext();

  return useQuery([GET_TOTW_WEEKS], async () => {
    const weeks = await apiClient.getTotwWeeks();
    weeks.forEach((w) => {
      // adjust for automatic timezone adjustment
      w.date = getUTCDate(new Date(w.date));
    });

    return weeks;
  });
}

export function useSchedulingWeeks() {
  const { apiClient } = useAPIContext();

  return useQuery([GET_SCHEDULING_WEEKS], async () => {
    const weeks = await apiClient.getSchedulingWeeks();
    weeks.forEach((w) => {
      // adjust for automatic timezone adjustment
      w.date = getUTCDate(new Date(w.date));
    });

    return weeks;
  });
}

export function useSchedulingGames() {
  const { apiClient } = useAPIContext();

  return useQuery([GET_SCHEDULING_GAMES], async () => {
    const games = await apiClient.getSchedulingGames();

    games.map((g: SchedulingGame) => {
      return (g.date_time = new Date(formatDateToLocal(g.date_time)));
    });

    return games;
  });
}

export function useConstantSchools() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_CONSTANT_SCHOOLS],
    async () => {
      const schools = await apiClient.getConstantSchools();
      return schools;
    },
    {
      enabled: authenticated,
    }
  );
}

export function useConstantCountries() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_CONSTANT_COUNTRIES],
    async () => {
      const schools = await apiClient.getConstantCountries();

      return schools;
    },
    {
      enabled: authenticated,
    }
  );
}

export function useConstantLeagues() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_CONSTANT_LEAGUES],
    async () => {
      const schools = await apiClient.getConstantLeagues();

      return schools;
    },
    {
      enabled: authenticated,
    }
  );
}

export function useOperationsData(club: string) {
  const { apiClient } = useAPIContext();

  return useQuery([GET_OPERATIONS_DATA, { club }], async () => {
    const data = await apiClient.getOperationsData(club);

    return data;
  });
}

export function useKitManagerPlayers(team: string) {
  const { apiClient } = useAPIContext();

  return useQuery([GET_KIT_MANAGER_PLAYERS, { team }], async () => {
    const players = await apiClient.getKitManagerPlayers(team);

    return players;
  });
}

export function useKitManagerActionLogs(team: string) {
  const { apiClient } = useAPIContext();

  return useQuery([GET_KIT_MANAGER_ACTION_LOGS, { team }], async () => {
    const action_logs = await apiClient.getKitManagerActionLogs(team);

    return action_logs;
  });
}

export function useKitManagerActions() {
  const { apiClient } = useAPIContext();

  return useQuery([GET_KIT_MANAGER_ACTIONS], async () => {
    const actions = await apiClient.getKitManagerActions();

    return actions;
  });
}
