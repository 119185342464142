import { Box, Grid, Stack } from "@mui/system";

import EditIcon from "@mui/icons-material/Edit";
import MutateScoutedPlayerAttributesDialog from "./Dialogs/MutatePlayerAttributesDialog";
import { PlayerAttributes } from "../../api/types";
import { Typography } from "@mui/material";
import { useState } from "react";

interface PlayerAttributesViewProps {
  playerAttributes: PlayerAttributes | undefined;
}

export default function PlayerAttributesView(props: PlayerAttributesViewProps) {
  // STATES
  const [
    showMutatePlayerAttributesDialog,
    setShowMutatePlayerAttributesDialog,
  ] = useState<boolean>(false);

  return (
    <>
      <MutateScoutedPlayerAttributesDialog
        showMutatePlayerAttributesDialog={showMutatePlayerAttributesDialog}
        setShowMutatePlayerAttributesDialog={
          setShowMutatePlayerAttributesDialog
        }
        playerAttributes={props.playerAttributes}
      />
      <Grid
        size={{ xs: 12 }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-evenly"}
        pb={4}
      >
        <Grid size={{ xs: 12 }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setShowMutatePlayerAttributesDialog(true);
            }}
          >
            <EditIcon fontSize="small" />
          </Box>
        </Grid>
        <Grid display={"flex"}>
          <Grid size={{ xs: 12, sm: 4 }} textAlign="center">
            <Stack>
              <Typography fontWeight="500">Player Comparison</Typography>
              <Typography sx={{ whiteSpace: "pre-line" }}>
                {props.playerAttributes?.comparison || "N/A"}
              </Typography>
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }} textAlign="center">
            <Typography fontWeight="500">Strengths</Typography>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {props.playerAttributes?.strengths || "N/A"}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }} textAlign="center">
            <Typography fontWeight="500">Areas to Improve</Typography>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {props.playerAttributes?.areas_to_improve || "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ textAlign: "center", mt: 4 }}>
          <Stack>
            <Typography fontWeight="500">Overview</Typography>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {props.playerAttributes?.overview || "N/A"}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
