import {
  COACHES_CORNER_ROUTE,
  EQUIPMENT_ROUTE,
  IDP_ROUTE,
  LOGIN_CALLBACK_ROUTE,
  NOTES_ROUTE,
  OPERATIONS_ROOT_ROUTE,
  PERFORMANCE_ROUTE,
  PLAYERS_ROUTE,
  ROOT_ROUTE,
  SCOUTING_REPORTS_ROUTE,
  SCOUTING_ROOT_ROUTE,
} from "./routes";
import { Route, Routes, useNavigate } from "react-router-dom";

import { HOTKEYS } from "./nav/HotKeys";
import { LoginCallback } from "@okta/okta-react";
import SecureRoute from "./components/SecureRoute";
import { lazy } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useUserContext } from "./contexts/UserContext";

const CoachesCornerPage = lazy(() => import("./pages/coaches_corner"));
const IDPPage = lazy(() => import("./pages/idp"));
const Equipment = lazy(() => import("./pages/equipment"));
const Operations = lazy(() => import("./pages/operations"));
const Performance = lazy(() => import("./pages/performance"));
// const Scouting = lazy(() => import("./pages/scouting/"));
const Players = lazy(() => import("./pages/players"));
const Notes = lazy(() => import("./pages/notes"));
const Search = lazy(() => import("./pages/search"));
const ScoutingReports = lazy(() => import("./pages/scouting_reports"));

export default function AppRoutes() {
  const { HomePage } = useUserContext();
  const navigate = useNavigate();

  useHotkeys(HOTKEYS["notes"].key, (event) => {
    navigate(HOTKEYS["notes"].route);
    event.preventDefault();
  });
  useHotkeys(HOTKEYS["players"].key, (event) => {
    navigate(HOTKEYS["players"].route);
    event.preventDefault();
  });
  useHotkeys(HOTKEYS["scouting_reports"].key, (event) => {
    navigate(HOTKEYS["scouting_reports"].route);
    event.preventDefault();
  });
  useHotkeys(HOTKEYS["search"].key, (event) => {
    navigate(HOTKEYS["search"].route);
    event.preventDefault();
  });

  //   console.debug(roles);
  //   console.debug(permissions);

  return (
    <Routes>
      <Route path={LOGIN_CALLBACK_ROUTE} element={<LoginCallback />} />

      <Route path={ROOT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={HomePage} />
      </Route>

      <Route path={COACHES_CORNER_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<CoachesCornerPage />} />
      </Route>

      <Route path={IDP_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<IDPPage />} />
      </Route>

      <Route path={PLAYERS_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Players />} />
      </Route>

      <Route path={NOTES_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Notes />} />
      </Route>

      <Route path={SCOUTING_REPORTS_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<ScoutingReports />} />
      </Route>

      {/* <Route path={SEARCH_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Search />} />
      </Route> */}

      <Route path={OPERATIONS_ROOT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Operations />} />
      </Route>

      <Route path={PERFORMANCE_ROUTE}>
        <Route path="" element={<SecureRoute />}>
          <Route path="" element={<Performance />} />
        </Route>
      </Route>

      <Route path={SCOUTING_ROOT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Players />} />
        <Route path="players" element={<Players />} />
        {/* <Route path="depth" element={<Scouting />} /> */}
        <Route path="notes" element={<Notes />} />
        <Route path="search" element={<Search />} />
      </Route>

      <Route path={EQUIPMENT_ROUTE} element={<SecureRoute />}>
        <Route path="" element={<Equipment />}></Route>
      </Route>
    </Routes>
  );
}
