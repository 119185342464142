import {
  CREATE_DEPTH_CHART,
  CREATE_DEPTH_CHART_PLAYER,
  CREATE_FOLDER,
  CREATE_KIT_MANAGER_ACTION_LOGS,
  CREATE_LIST,
  CREATE_NOTE,
  CREATE_PLAYER,
  CREATE_PLAYER_ATTRIBUTES,
  CREATE_SCHEDULING_GAME,
  CREATE_SCHEDULING_WEEK,
  CREATE_SCOUTED_PLAYER,
  CREATE_SCOUTING_REPORT,
  CREATE_TOTW_PLAYER,
  CREATE_TOTW_WEEK,
  CREATE_VISA,
  CREATE_WELCOME,
  DELETE_DEPTH_CHART_PLAYER,
  DELETE_FOLDER,
  DELETE_KIT_MANAGER_ACTION_LOG,
  DELETE_LIST,
  DELETE_SCHEDULING_GAME,
  DELETE_SCHEDULING_WEEK,
  DELETE_SCOUTING_REPORT,
  DELETE_TOTW_PLAYER,
  DELETE_TOTW_WEEK,
  GET_DEPTH_CHART,
  GET_DEPTH_CHARTS,
  GET_FOLDERS,
  GET_KIT_MANAGER_ACTIONS,
  GET_KIT_MANAGER_ACTION_LOGS,
  GET_KIT_MANAGER_PLAYERS,
  GET_LIST,
  GET_LISTS,
  GET_NOTIFICATIONS,
  GET_PLAYER,
  GET_PLAYERS,
  GET_PLAYER_ATTRIBUTES,
  GET_PLAYER_NOTES,
  GET_PLAYER_SCOUTING_REPORTS,
  GET_SCHEDULING_GAMES,
  GET_SCHEDULING_WEEKS,
  GET_SCOUTED_PLAYERS,
  GET_SCOUTING_REPORTS,
  GET_SHARES,
  GET_TOTW_WEEKS,
  UPDATE_DEPTH_CHART_PLAYER,
  UPDATE_FOLDER,
  UPDATE_LIST,
  UPDATE_LIST_PLAYERS,
  UPDATE_PLAYER,
  UPDATE_PLAYER_ATTRIBUTES,
  UPDATE_SCOUTED_PLAYER,
  UPDATE_SCOUTING_REPORT,
} from "./keys";
import {
  DepthChartCreate,
  DepthChartPlayer,
  Folder,
  FormInput,
  Note,
  Player,
  PlayerAttributes,
  PlayerAttributesCreate,
  PlayerCreate,
  PlayerList,
  PlayerUpdate,
  SchedulingGame,
  SchedulingWeek,
  ScoutedPlayer,
  ScoutedPlayerCreate,
  ScoutingReport,
  Share,
  TotwWeek,
} from "./types";
import { Dispatch, SetStateAction } from "react";
import { SEVERITY_SUCCESS, useAlertContext } from "../contexts/AlertContext";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PLAYERS_ROUTE } from "../routes";
import { SEARCH_PARAMS_LIST_ID } from "../constants";
import { useAPIContext } from "../contexts/APIContext";
import { useListContext } from "../contexts/ListContext";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useWebSocketContext } from "../contexts/WebSocketContext";

export const LIST_ACTION_PLAYER_ADD = "add";
export const LIST_ACTION_PLAYER_REMOVE = "remove";

export const useCreateDepthChart = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();

  return useMutation(
    (label: string) =>
      apiClient.createDepthChart({ label: label } as DepthChartCreate),
    {
      mutationKey: CREATE_DEPTH_CHART,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_DEPTH_CHART);
      },
    }
  );
};

export const useCreateDepthChartPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();

  return useMutation(
    (variables) => apiClient.createDepthChartPlayer(variables.depthChartPlayer),
    {
      mutationKey: CREATE_DEPTH_CHART_PLAYER,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_DEPTH_CHART);
      },
    }
  );
};

// Create a new scouted player record
export const useCreateScoutedPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (scoutedPlayer: ScoutedPlayerCreate) =>
      apiClient.createScoutedPlayer(scoutedPlayer),
    {
      mutationKey: CREATE_SCOUTED_PLAYER,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_SCOUTED_PLAYERS);

        ws?.send(GET_SCOUTED_PLAYERS);
        setAlertOptions({
          severity: SEVERITY_SUCCESS,
          message: "Added new scouted player",
        });
      },
    }
  );
};

export const useCreatePlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation((player: PlayerCreate) => apiClient.createPlayer(player), {
    mutationKey: CREATE_PLAYER,
    onSuccess: (result: any, variables: any, context: any) => {
      queryClient.invalidateQueries(GET_PLAYERS);
      ws?.send(GET_PLAYERS);

      setAlertOptions({
        severity: SEVERITY_SUCCESS,
        message: "created new player",
      });
    },
  });
};

export const useCreatePlayerAttributesMutation = () => {
  const { setAlertOptions } = useAlertContext();
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { ws } = useWebSocketContext();

  return useMutation(
    (playerAttributes: PlayerAttributesCreate) =>
      apiClient.createPlayerAttributes(playerAttributes),
    {
      mutationKey: CREATE_PLAYER_ATTRIBUTES,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_PLAYER_ATTRIBUTES);
        ws?.send(GET_PLAYER_ATTRIBUTES);

        setAlertOptions({
          message: "updated player attributes",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateScoutingReportMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const queryClient = useQueryClient();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (report: ScoutingReport) => apiClient.createScoutingReport(report),
    {
      mutationKey: CREATE_SCOUTING_REPORT,

      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_PLAYERS);
        queryClient.invalidateQueries(GET_PLAYER_SCOUTING_REPORTS);
        ws?.send(GET_PLAYERS);
        ws?.send(GET_PLAYER_SCOUTING_REPORTS);
        setAlertOptions({
          severity: SEVERITY_SUCCESS,
          message: "created new report",
        });
      },
    }
  );
};

export const useCreateListMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (list) => apiClient.createList(list.owner, list.name, list.players),
    {
      mutationKey: CREATE_LIST,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_LISTS);
        queryClient.invalidateQueries(GET_FOLDERS);
        setAlertOptions({
          link: `${PLAYERS_ROUTE}?${SEARCH_PARAMS_LIST_ID}=${result.id}`,
          message: `created new list: `,
          messageLink: result.name,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateNoteMutation = () => {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  const queryClient = useQueryClient();

  return useMutation(
    (note: Note) => apiClient.createNote(note.content, note.player_id, email),
    {
      mutationKey: CREATE_NOTE,
      onSuccess: (result: any, variables: any, context: any) => {
        ws?.send(GET_PLAYER_NOTES);
        ws?.send(GET_NOTIFICATIONS);
        queryClient.invalidateQueries(GET_PLAYER_NOTES);

        setAlertOptions({
          message: `created note: (${variables.content.substring(0, 20)}...)`,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateFolderMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();

  return useMutation((folder: Folder) => apiClient.createFolder(folder), {
    mutationKey: CREATE_FOLDER,

    onSuccess: (result: any, variables: any, context: any) => {
      queryClient.invalidateQueries(GET_LISTS);
      queryClient.invalidateQueries(GET_FOLDERS);
    },
  });
};

export const useCreateTotwWeekMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation((week: TotwWeek) => apiClient.createTotwWeek(week), {
    mutationKey: CREATE_TOTW_WEEK,

    onSuccess: (result: any, variables: any, context: any) => {
      ws?.send(GET_TOTW_WEEKS);

      setAlertOptions({
        message: "created new week",
        severity: SEVERITY_SUCCESS,
      });
    },
  });
};

export const useCreateTotwPlayersMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (variables) =>
      apiClient.createTotwPlayers(variables.weekId, variables.players),
    {
      mutationKey: CREATE_TOTW_PLAYER,

      onSuccess: (result: any, variables: any, context: any) => {
        ws?.send(GET_TOTW_WEEKS);

        setAlertOptions({
          message: "added player(s) successfully",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateSchedulingWeekMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (week: SchedulingWeek) => apiClient.createSchedulingWeek(week),
    {
      mutationKey: CREATE_SCHEDULING_WEEK,

      onSuccess: (result: any, variables: any, context: any) => {
        ws?.send(GET_SCHEDULING_WEEKS);

        setAlertOptions({
          message: "created new week",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateSchedulingGameMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (variables) =>
      apiClient.createSchedulingGame(variables.weekId, variables.game),
    {
      mutationKey: CREATE_SCHEDULING_GAME,

      onSuccess: (result: any, variables: any, context: any) => {
        ws?.send(GET_SCHEDULING_WEEKS);
        ws?.send(GET_SCHEDULING_GAMES);
        setAlertOptions({
          message: "created new game",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateKitManagerActionLogsMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (actionLogs) => apiClient.createKitManagerActionLogs(actionLogs),
    {
      mutationKey: CREATE_KIT_MANAGER_ACTION_LOGS,

      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_KIT_MANAGER_ACTIONS);
        queryClient.invalidateQueries(GET_KIT_MANAGER_ACTION_LOGS);
        queryClient.invalidateQueries(GET_KIT_MANAGER_PLAYERS);

        setAlertOptions({
          message: "added action log successfully",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateVisaMutation = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation((visa: FormInput) => apiClient.createVisa(visa), {
    mutationKey: CREATE_VISA,

    onSuccess: (result: any, variables: any, context: any) => {
      setAlertOptions({
        message: "visa form submitted",
        severity: SEVERITY_SUCCESS,
      });
    },
    retry: 1,
  });
};

export const useCreateWelcomeMutation = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation((welcome: FormInput) => apiClient.createWelcome(welcome), {
    mutationKey: CREATE_WELCOME,

    onSuccess: (result: any, variables: any, context: any) => {
      setAlertOptions({
        message: "welcome form submitted",
        severity: SEVERITY_SUCCESS,
      });
    },
    retry: 1,
  });
};

export const useDeleteDepthChartPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (variables) =>
      apiClient.deleteDepthChartPlayer(variables.depthChartPlayerId),
    {
      mutationKey: DELETE_DEPTH_CHART_PLAYER,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_DEPTH_CHARTS);

        setAlertOptions({
          message: `${variables.depthChartPlayerName} removed`,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useDeleteFolderMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();

  return useMutation(
    (folder: Folder) => apiClient.deleteFolder(folder.id, folder.owner),
    {
      mutationKey: DELETE_FOLDER,

      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_LISTS);
        queryClient.invalidateQueries(GET_FOLDERS);
      },
    }
  );
};

export const useDeleteListMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { listInContext, setListInContext } = useListContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return useMutation(
    (list: PlayerList) => apiClient.deleteList(list.id, list.owner),
    {
      mutationKey: DELETE_LIST,

      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_LISTS);
        queryClient.invalidateQueries(GET_FOLDERS);
        if (variables.id === listInContext?.id) {
          setListInContext(undefined);
        }
        if (
          window.location.pathname === PLAYERS_ROUTE &&
          searchParams.get(SEARCH_PARAMS_LIST_ID)
        ) {
          navigate(PLAYERS_ROUTE);
        }
      },
    }
  );
};

export const useDeleteNoteMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const queryClient = useQueryClient();

  return useMutation((id) => apiClient.deleteNote(id), {
    mutationKey: GET_PLAYER_NOTES,

    onSuccess: (result: any, variables: any, context: any) => {
      ws?.send(GET_PLAYER_NOTES);
      ws?.send(GET_PLAYER_SCOUTING_REPORTS);
      queryClient.invalidateQueries(GET_PLAYER_NOTES);
    },
  });
};

export const useDeleteScoutingReportMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const queryClient = useQueryClient();

  return useMutation((id) => apiClient.deleteScoutingReport(id), {
    mutationKey: DELETE_SCOUTING_REPORT,

    onSuccess: (result: any, variables: any, context: any) => {
      queryClient.invalidateQueries(GET_PLAYER_SCOUTING_REPORTS);
      ws?.send(GET_PLAYER_SCOUTING_REPORTS);
    },
  });
};

export const useDeleteTotwWeekMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();

  return useMutation((id) => apiClient.deleteTotwWeek(id), {
    mutationKey: DELETE_TOTW_WEEK,

    onSuccess: (result: any, variables: any, context: any) => {
      ws?.send(GET_TOTW_WEEKS);
    },
  });
};

export const useDeleteTotwPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();

  return useMutation(
    (variables) =>
      apiClient.deleteTotwPlayer(variables.weekId, variables.players),
    {
      mutationKey: DELETE_TOTW_PLAYER,

      onSuccess: (result: any, variables: any, context: any) => {
        ws?.send(GET_TOTW_WEEKS);
      },
    }
  );
};

export const useDeleteSchedulingWeekMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation((weekId) => apiClient.deleteSchedulingWeek(weekId), {
    mutationKey: DELETE_SCHEDULING_WEEK,

    onSuccess: (result: any, variables: any, context: any) => {
      ws?.send(GET_SCHEDULING_WEEKS);

      setAlertOptions({
        message: "game deleted",
        severity: SEVERITY_SUCCESS,
      });
    },
  });
};

export const useDeleteSchedulingGameMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation((gameId) => apiClient.deleteSchedulingGame(gameId), {
    mutationKey: DELETE_SCHEDULING_GAME,

    onSuccess: (result: any, variables: any, context: any) => {
      ws?.send(GET_SCHEDULING_WEEKS);
      ws?.send(GET_SCHEDULING_GAMES);

      setAlertOptions({
        message: "game deleted",
        severity: SEVERITY_SUCCESS,
      });
    },
  });
};

export const useDeleteKitManagerActionLog = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation((logId) => apiClient.deleteKitManagerActionLog(logId), {
    mutationKey: DELETE_KIT_MANAGER_ACTION_LOG,

    onSuccess: (result: any, variables: any, context: any) => {
      ws?.send(GET_KIT_MANAGER_ACTIONS);
      ws?.send(GET_KIT_MANAGER_PLAYERS);
      ws?.send(GET_KIT_MANAGER_ACTION_LOGS);

      setAlertOptions({
        message: "action log undo successful",
        severity: SEVERITY_SUCCESS,
      });
    },
  });
};

export const useShareMutation = () => {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { ws } = useWebSocketContext();

  return useMutation(
    (share: Share) =>
      apiClient.share(
        share.object_id,
        share.object_type,
        email,
        share.share_with_email
      ),
    {
      mutationKey: GET_SHARES,

      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_SHARES);
        ws?.send(GET_NOTIFICATIONS);
      },
    }
  );
};

export const useUnshareMutation = () => {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { ws } = useWebSocketContext();

  return useMutation(
    (share: Share) =>
      apiClient.unshare(
        share.id,
        email,
        share.share_with_email,
        share.object_id,
        share.object_type
      ),
    {
      mutationKey: GET_SHARES,

      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_SHARES);
        ws?.send(GET_NOTIFICATIONS);
      },
    }
  );
};

export const useUpdateDepthChartPlayer = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();

  return useMutation(
    (depthChartPlayer: DepthChartPlayer) =>
      apiClient.updateDepthChartPlayer(depthChartPlayer),
    {
      mutationKey: UPDATE_DEPTH_CHART_PLAYER,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_DEPTH_CHARTS);
      },
    }
  );
};

export const useUpdateFolderMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();

  return useMutation((folder: Folder) => apiClient.updateFolder(folder), {
    mutationKey: UPDATE_FOLDER,

    onSuccess: (result: any, variables: any, context: any) => {
      queryClient.invalidateQueries(GET_LISTS);
      queryClient.invalidateQueries(GET_FOLDERS);
    },
  });
};

export const useRenameListMutation = () => {
  // CONTEXT
  const { apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();
  const queryClient = useQueryClient();

  return useMutation(
    (list: PlayerList) => apiClient.updateListName(list.id, list.name, email),
    {
      mutationKey: UPDATE_LIST,

      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_LISTS);
        queryClient.invalidateQueries(GET_FOLDERS);
      },
    }
  );
};

export const useUpdateListFolderMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { setAlertOptions } = useAlertContext();

  return useMutation((list) => apiClient.updateListFolder(list), {
    mutationKey: UPDATE_LIST,

    onSuccess: (result: any, variables: any, context: any) => {
      //   console.debug(variables);
      const message = "Moved ";
      const link = `${PLAYERS_ROUTE}?${SEARCH_PARAMS_LIST_ID}=${variables.id}`;
      setAlertOptions({
        link: link,
        message: message,
        messageLink: variables.name,
        severity: SEVERITY_SUCCESS,
      });
      queryClient.invalidateQueries(GET_LISTS);
      queryClient.invalidateQueries(GET_FOLDERS);
    },
  });
};

interface playersMutationParams {
  player: Player;
  playerList: PlayerList | undefined;
}

export const useUpdateListPlayerMutation = (action: string) => {
  const { apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();
  const queryClient = useQueryClient();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    ({ player, playerList }: playersMutationParams) => {
      if (!playerList) {
        throw Error("no player list specified!");
      }

      // console.debug(player);
      // console.debug(playerList);
      return apiClient.updateListPlayers(action, playerList.id, email, [
        player,
      ]);
    },
    {
      mutationKey: UPDATE_LIST_PLAYERS,

      onSuccess: (result: any, variables: any, context: any) => {
        // console.debug(result);
        // console.debug(variables);
        // console.debug(context);

        ws?.send(GET_LIST);
        ws?.send(GET_LISTS);

        queryClient.invalidateQueries(GET_LIST);
        queryClient.invalidateQueries(GET_LISTS);
        queryClient.invalidateQueries(GET_FOLDERS);
        queryClient.invalidateQueries(GET_PLAYERS);

        setAlertOptions({
          link: `${PLAYERS_ROUTE}?${SEARCH_PARAMS_LIST_ID}=${variables.playerList?.id}`,
          message: `${
            action === LIST_ACTION_PLAYER_ADD ? "added" : "removed"
          } ${variables.player.name.toUpperCase()} ${
            action === LIST_ACTION_PLAYER_ADD ? "to" : "from"
          } `,
          messageLink: variables.playerList?.name,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useUpdateListPlayersMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (list) =>
      apiClient.updateListPlayers(
        list.action,
        list.id,
        list.owner,
        list.players
      ),
    {
      mutationKey: UPDATE_LIST,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_LIST);
        queryClient.invalidateQueries(GET_LISTS);
        queryClient.invalidateQueries(GET_FOLDERS);
        queryClient.invalidateQueries(GET_PLAYERS);

        const formattedAction =
          variables.action === "remove" ? "removed" : "added";
        const preposition = variables.action === "remove" ? "from" : "to";
        const message = `${formattedAction} player(s) ${preposition}`;
        const link = `${PLAYERS_ROUTE}?${SEARCH_PARAMS_LIST_ID}=${variables.id}`;

        setAlertOptions({
          link: link,
          message: message,
          messageLink: variables.name,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useUpdateNoteMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const queryClient = useQueryClient();

  return useMutation((note: Note) => apiClient.updateNote(note), {
    mutationKey: GET_PLAYER_NOTES,

    onSuccess: (result: any, variables: any, context: any) => {
      ws?.send(GET_PLAYER_NOTES);
      queryClient.invalidateQueries(GET_PLAYER_NOTES);
    },
  });
};

export const useUpdatePlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();

  return useMutation((player: PlayerUpdate) => apiClient.updatePlayer(player), {
    mutationKey: UPDATE_PLAYER,
    onSuccess: (result: any, variables: any, context: any) => {
      queryClient.invalidateQueries(GET_PLAYER);
      queryClient.invalidateQueries(GET_LIST);
      queryClient.invalidateQueries(GET_PLAYERS);
    },
    retry: false,
  });
};

export const useUpdatePlayerAttributesMutation = () => {
  const { setAlertOptions } = useAlertContext();
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const queryClient = useQueryClient();

  return useMutation(
    (playerAttributes: PlayerAttributes) =>
      apiClient.updatePlayerAttributes(playerAttributes),
    {
      mutationKey: UPDATE_PLAYER_ATTRIBUTES,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_PLAYER_ATTRIBUTES);
        ws?.send(GET_PLAYER_ATTRIBUTES);

        setAlertOptions({
          message: "updated player attributes",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useUpdateScoutedPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const queryClient = useQueryClient();
  const { ws } = useWebSocketContext();

  return useMutation(
    (scoutedPlayer: ScoutedPlayer) =>
      apiClient.updateScoutedPlayer(scoutedPlayer),
    {
      mutationKey: UPDATE_SCOUTED_PLAYER,
      onSuccess: (result: any, variables: any, context: any) => {
        queryClient.invalidateQueries(GET_SCOUTED_PLAYERS);
        ws?.send(GET_SCOUTING_REPORTS);
      },
      retry: false,
    }
  );
};

export const useUpdateScoutingReportMutation = (
  setReportInContext: Dispatch<SetStateAction<ScoutingReport | undefined>>
) => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (report: ScoutingReport) =>
      apiClient.updateScoutingReport(report.id, report),
    {
      mutationKey: UPDATE_SCOUTING_REPORT,

      onSuccess: (result: any, variables: any, context: any) => {
        setReportInContext(result as ScoutingReport);
        ws?.send(GET_PLAYER_NOTES);
        ws?.send(GET_SCOUTING_REPORTS);
        ws?.send(GET_PLAYER_SCOUTING_REPORTS);

        setAlertOptions({
          message: "updated scouting report",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useUpdateSchedulingGameMutation = () => {
  const { apiClient } = useAPIContext();
  const { ws } = useWebSocketContext();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (game: SchedulingGame) => apiClient.updateSchedulingGame(game),
    {
      mutationKey: UPDATE_SCOUTING_REPORT,

      onSuccess: (result: any, variables: any, context: any) => {
        ws?.send(GET_SCHEDULING_WEEKS);
        ws?.send(GET_SCHEDULING_GAMES);

        setAlertOptions({
          message: "updated game",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};
