import { Fab, SelectChangeEvent, Tooltip, useTheme } from "@mui/material";
import {
  SCOUTING_DEPTH_ROUTE,
  SCOUTING_ROOT_ROUTE,
} from "../../routes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import DepthChart from "./DepthChart";
import { GridFilterModel } from "@mui/x-data-grid-premium";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Player } from "../../api/types";
import PlayerProfileDialog from "../../components/dialogs/PlayerProfileDialog";
import Players from "../players";
/* eslint-disable */
import { useScoutingReportDialogContext } from "../../contexts/ScoutingReportDialogContext";
import { useTeamContext } from "../../contexts/TeamContext";
import { useUserContext } from "../../contexts/UserContext";

const Scouting = () => {
  const theme = useTheme();
  const location = useLocation();
  const { handleCreateReportClick } = useScoutingReportDialogContext();
  const { team } = useTeamContext();
  const { primaryRole } = useUserContext();
  const navigate = useNavigate();

  const [el, setEl] = useState<JSX.Element>();
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>({
    items: [
      {
        id: "group-select",
        field: "grouping",
        operator: "isEmpty",
        value: undefined,
      },
      {
        id: "player-name-search",
        field: "player_name",
        operator: "contains",
        value: "",
      },
    ],
    logicOperator: "and",
    quickFilterLogicOperator: "and",
    quickFilterValues: [],
  } as GridFilterModel);
  const [playerInContext, setPlayerInContext] = useState<
    Player | null | undefined
  >();
  const [playerProfileModalOpen, setPlayerProfileModalOpen] =
    useState<boolean>(false);

  // EFFECTS
  useEffect(() => {
    setFilterModel({
      items: [
        {
          id: "group-select",
          field: "grouping",
          operator: "isEmpty",
          value: undefined,
        },
        {
          id: "player-name-search",
          field: "player_name",
          operator: "contains",
          value: "",
        },
      ],
      logicOperator: "and",
      quickFilterLogicOperator: "and",
      quickFilterValues: [],
    } as GridFilterModel);
  }, [team]);

  useEffect(() => {
    const handleGroupChange = (event: SelectChangeEvent) => {
      setFilterModel({
        items: [
          {
            id: "group-select",
            field: "grouping",
            operator: event.target.value ? "equals" : "isEmpty",
            value: event.target.value ? event.target.value : undefined,
          },
          {
            id: "player-name-search",
            field: "player_name",
            operator: "contains",
            value: "",
          },
        ],
        logicOperator: "and",
        quickFilterLogicOperator: "and",
        quickFilterValues: [],
      } as GridFilterModel);
      navigate(SCOUTING_ROOT_ROUTE);

      // console.debug(filterModel);
    };

    // console.debug(location.pathname);

    switch (location.pathname) {
      case SCOUTING_DEPTH_ROUTE:
        setEl(<DepthChart />);
        break;
      // case SCOUTING_SHORTLIST_ROUTE:
      // setEl(
      //   <PitchShortList
      //     grouping={group}
      //     groupingSelectEl={groupingSelectEl}
      //     setScoutedPlayerInContext={setPlayerInContext}
      //     setScoutedPlayerProfileModalOpen={
      //       setScoutedPlayerProfileModalOpen
      //     }
      //   />
      // );
      // break;
      // case SCOUTING_TEAM_OF_THE_WEEK_ROUTE:
      //   setEl(
      //     <TeamOfTheWeekChart
      //       grouping={group}
      //       groupingSelectEl={groupingSelectEl}
      //       setScoutedPlayerInContext={setScoutedPlayerInContext}
      //       setScoutedPlayerProfileModalOpen={
      //         setScoutedPlayerProfileModalOpen
      //       }
      //     />
      //   );
      // break;
      // case SCOUTING_SCHEDULING:
      //   setEl(<Scheduling />);
      //   break;
      default:
        setEl(<Players />);
    }
  }, [navigate, location.pathname, filterModel, playerInContext]);

  return (
    <>
      {playerInContext && (
        <PlayerProfileDialog
        // handleClose={() => {
        //   setPlayerProfileModalOpen(false);
        //   setPlayerInContext(null);
        // }}
        // open={playerProfileModalOpen}
        // player={playerInContext}
        // setPlayerInContext={setPlayerInContext}
        />
      )}

      {/* Dynamically render element */}
      {el}

      {location.pathname == SCOUTING_ROOT_ROUTE && (
        <Tooltip
          disableFocusListener
          key="create-scouting-report-tooltip"
          title="CREATE SCOUTING REPORT"
        >
          <Fab
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={() => {
              handleCreateReportClick(undefined);
            }}
            sx={{
              color: theme.palette.primary.contrastText,
              bgcolor: theme.palette.primary.main,
              "&:hover": {
                bgcolor: theme.palette.primary.main,
              },
              position: "absolute",
              top: 75,
              right: 25,
            }}
          >
            <NoteAddIcon fontSize="small" />
          </Fab>
        </Tooltip>
      )}

      {/* <Tooltip
        disableFocusListener
        key="go-home-report-tooltip"
        title="CHOOSE GROUP"
      >
        <Fab
          color="secondary"
          aria-label="edit"
          size="small"
          onClick={() => {
            setGrouping(SCOUTING_REPORT_GROUP_NONE);
            setLandingOpen(true);
          }}
          sx={{
            color: theme.palette.primary.contrastText,
            bgcolor: theme.palette.primary.main,
            "&:hover": {
              bgcolor: theme.palette.primary.main,
            },
            position: "absolute",
            top: 75,
            right: 75,
          }}
        >
          <HomeIcon fontSize="small" />
        </Fab>
      </Tooltip> */}

      {/* <Tooltip disableFocusListener key="show-pitch-tooltip" title="PITCH VIEW">
        <Fab
          color="secondary"
          aria-label="edit"
          size="small"
          onClick={() => {
            navigate(SCOUTING_DEPTH_ROUTE);
          }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              bgcolor: theme.palette.primary.main,
            },
            position: "absolute",
            right: 125,
            top: 75,
          }}
        >
          {FieldIcon()}
        </Fab>
      </Tooltip> */}

      {/* <Tooltip
        disableFocusListener
        key="show-repots-tooltip"
        title="PLAYERS VIEW"
      >
        <Fab
          color="secondary"
          aria-label="edit"
          size="small"
          onClick={() => {
            navigate(SCOUTING_REPORTS_ROUTE);
          }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              bgcolor: theme.palette.primary.main,
            },
            position: "absolute",
            right: 175,
            top: 75,
          }}
        >
          <PersonIcon fontSize="small" />
        </Fab>
      </Tooltip> */}
    </>
  );
};

export default Scouting;
