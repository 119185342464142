import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PlayerAttributes, PlayerAttributesCreate } from "../../../api/types";
import {
  useCreatePlayerAttributesMutation,
  useUpdatePlayerAttributesMutation,
} from "../../../api/mutations";

import { usePlayerContext } from "../../../contexts/PlayerContext";

export interface MutatePlayerAttributesDialogProps {
  showMutatePlayerAttributesDialog: boolean;
  setShowMutatePlayerAttributesDialog: Dispatch<SetStateAction<boolean>>;
  playerAttributes?: PlayerAttributes;
}

export default function MutatePlayerAttributesDialog(
  props: MutatePlayerAttributesDialogProps
) {
  // CONTEXTS
  const { playerInContext } = usePlayerContext();

  // MUTATIONS
  const createPlayerAttributes = useCreatePlayerAttributesMutation();
  const updatePlayerAttributes = useUpdatePlayerAttributesMutation();

  // STATES
  const [overview, setOverview] = useState<string>("");
  const [comparison, setComparison] = useState<string>("");
  const [strengths, setStrengths] = useState<string>("");
  const [areasToImprove, setAreasToImprove] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);

  // FUNCTIONS
  const closeDialog = () => {
    props.setShowMutatePlayerAttributesDialog(false);
  };

  const handleSubmit = () => {
    if (playerInContext) {
      const attributeObj: { [key: string]: any } = {
        overview: overview,
        comparison: comparison,
        strengths: strengths,
        areas_to_improve: areasToImprove,
        player_id: playerInContext.id,
      };

      if (!props.playerAttributes) {
        createPlayerAttributes.mutate(attributeObj as PlayerAttributesCreate);

        console.log(attributeObj);
      } else {
        const obj = Object.fromEntries(
          Object.entries({ ...attributeObj, id: props.playerAttributes.id })
        );

        updatePlayerAttributes.mutate(obj as unknown as PlayerAttributes);

        console.log(obj);
      }

      // IF PLAYER ATTRIBUTES EXIST, UPDATE
      // ELSE CREATE NEW ONE

      closeDialog();
    }
  };

  // EFFECTS
  useEffect(() => {
    setOverview(props.playerAttributes?.overview || "");
    setComparison(props.playerAttributes?.comparison || "");
    setStrengths(props.playerAttributes?.strengths || "");
    setAreasToImprove(props.playerAttributes?.areas_to_improve || "");
  }, [
    playerInContext,
    props.playerAttributes,
    props.showMutatePlayerAttributesDialog,
  ]);

  useEffect(() => {
    if (
      overview !== props.playerAttributes?.overview ||
      comparison !== props.playerAttributes?.comparison ||
      strengths !== props.playerAttributes?.strengths ||
      areasToImprove !== props.playerAttributes?.areas_to_improve
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    overview,
    comparison,
    strengths,
    areasToImprove,
    props.playerAttributes?.overview,
    props.playerAttributes?.comparison,
    props.playerAttributes?.strengths,
    props.playerAttributes?.areas_to_improve,
  ]);

  return (
    <Dialog
      open={props.showMutatePlayerAttributesDialog}
      onClose={closeDialog}
      fullWidth
    >
      <DialogTitle>Player Attributes</DialogTitle>
      <DialogContent>
        <Stack spacing={2} padding="8px">
          <TextField
            label="Comparison"
            value={comparison || ""}
            multiline
            onChange={(event) => {
              setComparison(event.target.value);
            }}
          />
          <TextField
            label="Strengths"
            value={strengths || ""}
            multiline
            onChange={(event) => {
              setStrengths(event.target.value);
            }}
          />
          <TextField
            label="Areas to Improve"
            value={areasToImprove || ""}
            multiline
            onChange={(event) => {
              setAreasToImprove(event.target.value);
            }}
          />
          <TextField
            label="Overview"
            value={overview || ""}
            multiline
            onChange={(event) => {
              setOverview(event.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={closeDialog}>CANCEL</Button>
        <Button disabled={disabled} onClick={handleSubmit}>
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
}
